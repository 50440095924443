import React from 'react';

const MySvgComponent = ({ stroke }) => (


    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="20" height="20" viewBox="0 0 200.000000 200.000000"  preserveAspectRatio="xMidYMid meet">  
        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> 
            <path d="M480 1930 c-17 -17 -20 -33 -20 -125 l0 -105 -105 0 c-92 0 -108 -3 -125 -20 -20 -20 -20 -33 -20 -809 l0 -790 22 -15 c20 -14 98 -16 643 -16 520 0 624 2 641 14 16 13 19 29 22 130 l4 116 114 0 c102 0 114 2 124 19 6 13 10 236 10 653 l0 633 -168 168 -167 167 -478 0 c-464 0 -478 -1 -497 -20z m850 -249 c0 -132 3 -162 16 -175 13 -13 43 -16 175 -16 l159 0 0 -540 0 -540 -553 2 -552 3 -3 713 -2 712 380 0 380 0 0 -159z m320 -78 c0 -2 -47 -3 -105 -3 l-105 0 0 107 0 108 105 -105 c58 -58 105 -106 105 -107z m-1190 -636 c0 -522 2 -626 14 -643 14 -18 32 -19 485 -22 l471 -3 0 -69 0 -70 -555 0 -555 0 0 715 0 715 70 0 70 0 0 -623z" fill={stroke}/> 
            <path d="M754 1666 c-17 -13 -19 -30 -22 -161 -3 -121 -1 -150 12 -171 l16 -24 200 0 c187 0 200 1 210 19 5 11 10 85 10 170 0 194 16 181 -225 181 -141 0 -186 -3 -201 -14z m316 -171 l0 -75 -115 0 -115 0 0 68 c0 38 3 72 7 75 3 4 55 7 115 7 l108 0 0 -75z" fill={stroke}/> 
            <path d="M745 1146 c-19 -28 -9 -58 25 -76 24 -13 679 -14 714 0 34 12 44 54 21 80 -17 19 -33 20 -381 20 l-364 0 -15 -24z" fill={stroke}/> 
            <path d="M758 918 c-28 -22 -31 -55 -9 -77 20 -20 31 -21 380 -21 l360 0 15 22 c21 30 20 44 -4 68 -19 19 -33 20 -373 20 -267 0 -357 -3 -369 -12z" fill={stroke}/> 
            <path d="M768 679 c-32 -18 -41 -48 -23 -75 l15 -24 364 0 c339 0 364 1 379 18 22 25 21 48 -3 72 -19 19 -33 20 -367 20 -216 0 -355 -4 -365 -11z" fill={stroke}/> 
        </g> 
    </svg> 


);

export default MySvgComponent;