import React, { PureComponent } from 'react';
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';



export default class CustomAreaChartWorldNeg extends PureComponent {
  render() {
    let { chartData } = this.props;


    let maxPossibleSum = 0;
chartData.forEach(entry => {
  const sum = entry.France + entry.Brazil + entry.Australia + entry.China + entry.USA + entry.Singapore + entry.Canada + entry.Japan + entry.Nigeria + entry.Pakistan + entry.Russia + entry.UAE + entry.UK;
  if (sum > maxPossibleSum) {
    maxPossibleSum = sum;
  }
});

// Update max values for entries with all 0 pos, neg, neu values
chartData.forEach(entry => {
  if (entry.France === 0 && entry.Brazil === 0 && entry.Australia === 0 && entry.China === 0 && entry.USA === 0 && entry.Singapore === 0 && entry.Canada === 0 && entry.Japan === 0 && entry.Nigeria === 0 && entry.Pakistan === 0 && entry.Russia === 0 && entry.UAE === 0 && entry.UK === 0) {
    entry.max = maxPossibleSum;
  }
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let colors = [
      "#470f17",
      "#470f17",
      "#470f17",
      "#470f17",
      "#470f17",
      "#470f17",
      "#470f17",
      "#f699a6",
      "#f699a6",
      "#f699a6",
      "#f699a6",
      "#f699a6",
      "#f699a6",    
    ]
    let i = 12;
    return (
      <div className="custom-tooltip bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 rounded-lg shadow-xl p-1">
        <p className="label font-bold text-center">{`${label}`}</p>
        <div className='grid grid-cols-3 gap-2'>
          {payload.map((pld) => {
            let color = colors.at(i);
            i--;
            return (
            <div className='rounded-lg p-1' style={{ backgroundColor: pld.fill }}>
              <div className='text-xs text-center font-bold' style={{ color: color }}>{pld.value}</div>
              <div className='text-xs text-center' style={{ color: color }}>{pld.dataKey}</div>
            </div>
          )}
          )}
        </div>
      </div>
    );
  }

  return null;
};
    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={chartData}
          margin={{
            top: 30,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          {/* <YAxis domain={[0, 100]}/> */}
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
          {/* <Legend /> */}
          <Area type="monotone" dataKey="France" stackId="a" stroke="#470f17" fill="#470f17" />
          <Area type="monotone" dataKey="Brazil" stackId="a" stroke="#5f141e" fill="#5f141e" />
          <Area type="monotone" dataKey="Australia" stackId="a" stroke="#771926" fill="#771926" />
          <Area type="monotone" dataKey="China" stackId="a" stroke="#8e1e2e" fill="#8e1e2e" />
          <Area type="monotone" dataKey="USA" stackId="a" stroke="#a62335" fill="#a62335" />
          <Area type="monotone" dataKey="Singapore" stackId="a" stroke="#be283d" fill="#be283d" />
          <Area type="monotone" dataKey="Canada" stackId="a" stroke="#d62d45" fill="#d62d45" />
          <Area type="monotone" dataKey="Japan" stackId="a" stroke="#ee334d" fill="#ee334d" />
          <Area type="monotone" dataKey="Nigeria" stackId="a" stroke="#ef475e" fill="#ef475e" />
          <Area type="monotone" dataKey="Pakistan" stackId="a" stroke="#f15b70" fill="#f15b70" />
          <Area type="monotone" dataKey="Russia" stackId="a" stroke="#f37082" fill="#f37082" />
          <Area type="monotone" dataKey="UAE" stackId="a" stroke="#f48494" fill="#f48494" />
          <Area type="monotone" dataKey="UK" stackId="a" stroke="#f699a6" fill="#f699a6" />
          {/* <Area type="monotone" dataKey="max" stackId="a" stroke="#d6cec3" fill="#d6cec3" /> */}
        </AreaChart>
        </ResponsiveContainer>
    );
  }
}
