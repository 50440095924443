import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import backgroundImage from "../assets/backgroundMain.jpg";
import articleIcon from "../assets/article-icon.png"
import BigSingleHorizontalBar from "../graphs/BigSingleHorizontalBar";
import MicroPieChart from "../graphs/MicroPieChart";
import { useMediaQuery } from "react-responsive";
import Slider from "rc-slider";
import { BarLoader } from "react-spinners";
import updown from "../assets/uda.svg";
import updownarrow from "../assets/upDownArrow.svg"


function NewspaperView() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [monthwiseData, setMonthwiseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickCountNews, setClickCountNews] = useState(false);
  const [clickCountCountry, setClickCountCountry] = useState(true);
  const [clickCountTotal, setClickCountTotal] = useState(true);
  const [clickCountPositive, setClickCountPosite] = useState(false);
  const [clickCountNeutral, setClickCountNeutral] = useState(false);
  const [clickCountNegative, setClickCountNegative] = useState(false);
  const [isAllTime, setIsAllTime] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  
  const [newspaperData, setNewspaperData] = useState([]);

  let months = [
    {name: "Jan", number: 1},
    {name: "Feb", number: 2},
    {name: "Mar", number: 3},
    {name: "Apr", number: 4},
    {name: "May", number: 5},
    {name: "Jun", number: 6},
    {name: "Jul", number: 7},
    {name: "Aug", number: 8},
    {name: "Sep", number: 9},
    {name: "Oct", number: 10},
    {name: "Nov", number: 11},
    {name: "Dec", number: 12}
  ];

  const date = new Date();
  const currentMonths = date.getMonth();

  if(currentMonths >= 8){
    months = months.slice(currentMonths-8,currentMonths);
  }
  else{
    months = months.slice(currentMonths+4).concat(months.slice(0, currentMonths));
  }

  const [allFlags, setAllFlags] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });
  const [allNewspaperData, setAllNewspaperData] = useState([]);
  const [selectedSort, setSelectedSort] = useState("DEFAULT");

  let page = 1;
  let first = 0
  let last = 25
  let incr = 25

  if (document.referrer === window.parent.location.origin+"/newspaper-detail") {
    page = localStorage.getItem("pageNumber") ? localStorage.getItem("pageNumber") : 1
    first = localStorage.getItem("firstNewspaper") ? localStorage.getItem("firstNewspaper") : 0
    last = localStorage.getItem("lastNewspaper") ? localStorage.getItem("lastNewspaper") : 25
    incr = localStorage.getItem("increaseNumber") ? localStorage.getItem("increaseNumber") : 25
  }

  const [pageValue, setPageValue] = useState(page);
  const [firstNewspaperNumber, setFirstNewspaperNumber] = useState(first);
  const [lastNewspaperNumber, setLastNewspaperNumber] = useState(last);
  const [increaseNumber, setIncreaseNumber] = useState(incr);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  const increaseValue = () => {
    if(lastNewspaperNumber + 25 < allNewspaperData.length){
      scrollToTop();
      setIncreaseNumber(25);
      setPageValue(pageValue + 1);
      setFirstNewspaperNumber(firstNewspaperNumber + increaseNumber);
      setLastNewspaperNumber(lastNewspaperNumber + increaseNumber);
    }
    else if (allNewspaperData.length - lastNewspaperNumber > 0){
      scrollToTop();
      let a = allNewspaperData.length - lastNewspaperNumber;
      setIncreaseNumber(a);
      setPageValue(pageValue + 1);
      setFirstNewspaperNumber(firstNewspaperNumber + 25);
      setLastNewspaperNumber(lastNewspaperNumber + a);
    }
  };

  const decreaseValue = () => {
    if (pageValue > 1){
      scrollToTop();
      setPageValue(pageValue - 1);
      setFirstNewspaperNumber(firstNewspaperNumber - 25);
      setLastNewspaperNumber(lastNewspaperNumber - increaseNumber);
      if (increaseNumber !== 25)
        setIncreaseNumber(25);
    }
  };
  

  const handleSliderChange = (value) => {
    setIsAllTime(false);
    setSelectedMonth(value);
    // console.log("Selected Month:", months[value]);
  };

  const allTimeData = () => {
    setIsAllTime(true);
    setSelectedMonth("All");
  };
  
  /*
  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSort(selectedValue);
    //console.log(selectedSort);
    if (selectedValue === 'NAZ') {
      // Call the function for sorting by Newspaper A-Z
      sortAlphabeticallyNewspaperAZ();
    } 
    else if (selectedValue === 'NZA') {
      // Call the function for sorting by Newspaper Z-A
      sortAlphabeticallyNewspaperZA();
    }
    else if (selectedValue === 'ALH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByTotalLH();
    }
    else if (selectedValue === 'AHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByTotalHL();
    }
    else if (selectedValue === 'NEGLH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByNegativeLH();
    }
    else if (selectedValue === 'NEGHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByNegativeHL();
    }
    else if (selectedValue === 'NEULH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByNeutralLH();
    }
    else if (selectedValue === 'NEUHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByNeutralHL();
    }
    else if (selectedValue === 'POSLH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByPositiveLH();
    }
    else if (selectedValue === 'POSHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByPositiveHL();
    }
  };
  */

  const sliderMarks = months.map((item) => item.name).reduce((acc, month, index) => {
    if (!isAllTime && index === selectedMonth){
      acc[index] = {
        style: { borderColor: "black", height: "5%" }, // Set the style for the vertical line
        label: <b style={{ color: "black", fontSize: "10px" }}>{month}</b>, // Set the label style
      };      
    }
    else {
    acc[index] = {
      style: { borderColor: "grey", height: "5%" }, // Set the style for the vertical line
      label: <p style={{ color: "grey", fontSize: "10px" }}>{month}</p>, // Set the label style
    };
  }
    return acc;
  }, {});

  const handleClick = (newspaper) => {
    const aggregatedData = {};

    // Iterate through the monthwiseData array and accumulate data for each newspaper
    monthwiseData.forEach((data) => {
      const {
        newspaper_name,
        positive,
        negative,
        neutral,
        country,
        logo,
        monthly_readers,
        political_inclination,
        basic_info,
        owner,
      } = data;


      if (!aggregatedData[newspaper_name]) {
        aggregatedData[newspaper_name] = {
          newspaper_name,
          positive,
          negative,
          neutral,
          country,
          logo,
          monthly_readers,
          political_inclination,
          basic_info,
          owner,
        };
      } else {
        aggregatedData[newspaper_name].positive += positive;
        aggregatedData[newspaper_name].negative += negative;
        aggregatedData[newspaper_name].neutral += neutral;
      }
    });

    // Convert the aggregatedData object back to an array of objects
    const resultArray = Object.values(aggregatedData);

    const foundNewspaper = resultArray.find(
      (item) => item.newspaper_name === newspaper.newspaper_name
    );
    //console.log(foundNewspaper)
    window.localStorage.setItem("hoveredNewspaper", newspaper.newspaper_name);
    window.localStorage.setItem(
      "hoveredNewspaperPositive",
      foundNewspaper.positive
    );
    window.localStorage.setItem(
      "hoveredNewspaperNegative",
      foundNewspaper.negative
    );
    window.localStorage.setItem(
      "hoveredNewspaperNeutral",
      foundNewspaper.neutral
    );
    window.localStorage.setItem(
      "hoveredNewspaperCountry",
      foundNewspaper.country
    );
    window.localStorage.setItem("hoveredNewspaperLogo", foundNewspaper.logo);
    window.localStorage.setItem(
      "hoveredNewspaperReaders",
      foundNewspaper.monthly_readers
    );
    window.localStorage.setItem(
      "hoveredNewspaperPoliticalInclination",
      foundNewspaper.political_inclination
    );
    window.localStorage.setItem(
      "hoveredNewspaperInfo",
      foundNewspaper.basic_info
    );
    window.localStorage.setItem(
      "hoveredNewspaperOwner",
      foundNewspaper.owner
    );

    window.localStorage.setItem(
      "pageNumber",
      pageValue
    );
    window.localStorage.setItem(
      "firstNewspaper",
      firstNewspaperNumber
    );
    window.localStorage.setItem(
      "lastNewspaper",
      lastNewspaperNumber
    );
    window.localStorage.setItem(
      "increaseNumber",
      increaseNumber
    );

    window.dispatchEvent(new Event("storage"));

  };

  useEffect(() => {
    setIsLoading(true);
    if (!isAllTime) {
    const filteredMonthlyData = monthwiseData.filter(
      (item) => item.month === months[selectedMonth].number
    );

    setNewspaperData(filteredMonthlyData);
    }
    else{
      const aggregatedData = {};

      // Iterate through the monthwiseData array and accumulate data for each country
      monthwiseData.forEach((data) => {
        const { newspaper_name, positive, negative, neutral, logo, country } =
          data;
  
        if (!aggregatedData[newspaper_name]) {
          aggregatedData[newspaper_name] = {
            newspaper_name,
            positive,
            negative,
            neutral,
            logo,
            country,
          };
        } else {
          aggregatedData[newspaper_name].positive += positive;
          aggregatedData[newspaper_name].negative += negative;
          aggregatedData[newspaper_name].neutral += neutral;
        }
      });
  
      // Convert the aggregatedData object back to an array of objects
      const resultArray = Object.values(aggregatedData);
  
      setNewspaperData(resultArray);
    }
    setIsLoading(false);
  }, [selectedMonth]);

  useEffect(() => {
    const fetchAllFlags = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/country/getallCountryArticleNumber",
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const getData = await response.json();
          setIsLoading(false);
          const uniqueCountries = [];
          const countryNames = {};

          getData.forEach((item) => {
            const { countryName, flagLogo } = item;
            if (!countryNames[countryName]) {
              countryNames[countryName] = true;
              uniqueCountries.push({ countryName, flagLogo });
            }
          });

          // console.log(uniqueCountries);

          setAllFlags(uniqueCountries);
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    };

    fetchAllFlags();

    async function fetchNewspapers() {
      try {
        setIsLoading(true);
        const response = await fetch(
          "https://api.kutniti.watch/api/newspaper/getAllNewspapers"
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        //console.log(data)

        const newArrayOfObjects = data.flatMap((newspaper) =>
          Object.entries(newspaper.articles).map(([month, stats]) => ({
            newspaper_id: newspaper.newspaper_id,
            newspaper_name: newspaper.newspaper_name,
            monthly_readers: newspaper.monthly_readers,
            link: newspaper.link,
            country: newspaper.country,
            logo: newspaper.logo,
            political_inclination: newspaper.political_inclination,
            basic_info: newspaper.basic_info,
            owner: newspaper.owner,
            month: monthToNumber(month), // Convert month to number (e.g., "June" to 6)
            positive: stats.Positive || 0, // Set to 0 if not present
            negative: stats.Negative || 0, // Set to 0 if not present
            neutral: stats.Neutral || 0, // Set to 0 if not present
          }))
        );
        //console.log(newArrayOfObjects)

        function monthToNumber(month) {
          // Map month names to numbers
          const monthMap = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
          };

          return monthMap[month] || 0; // Default to 0 if month is not found
        }

        //---------Now make objects for remaining months with 0s in positive neg and neu---

        const monthlyData = [];

        // Iterate over each object in the original array
        newArrayOfObjects.forEach((item) => {
          // Iterate over 12 months
          for (let month = 1; month <= 12; month++) {
            // Check if the month already exists in the original data
            const existingMonthData = newArrayOfObjects.find(
              (x) => x.newspaper_id === item.newspaper_id && x.month === month
            );

            if (existingMonthData) {
              // If the month exists, push the existing data
              monthlyData.push(existingMonthData);
            } else {
              // If the month does not exist, create a new object with zeros
              monthlyData.push({
                ...item,
                month: month,
                positive: 0,
                negative: 0,
                neutral: 0,
              });
            }
          }
        });
        //----------------------------sTORE ALL MONTHS DATA IN monthwiseData------------------------------------
        // Remove duplicates
        const uniqueMonthlyData = monthlyData.filter((item, index, self) => {
          // Find the index of the first occurrence of the current item
          const firstIndex = self.findIndex(
            (otherItem) =>
              otherItem.newspaper_name === item.newspaper_name &&
              otherItem.month === item.month
          );

          // Keep the current item only if its index is the same as the first index found
          return index === firstIndex;
        });

        setMonthwiseData(uniqueMonthlyData);

        if(isFirstTime){
          const aggregatedData = {};

          // Iterate through the monthwiseData array and accumulate data for each country
          uniqueMonthlyData.forEach((data) => {
            const { newspaper_name, positive, negative, neutral, logo, country } =
              data;
      
            if (!aggregatedData[newspaper_name]) {
              aggregatedData[newspaper_name] = {
                newspaper_name,
                positive,
                negative,
                neutral,
                logo,
                country,
              };
            } else {
              aggregatedData[newspaper_name].positive += positive;
              aggregatedData[newspaper_name].negative += negative;
              aggregatedData[newspaper_name].neutral += neutral;
            }
          });
      
          // Convert the aggregatedData object back to an array of objects
          const resultArray = Object.values(aggregatedData);
          setNewspaperData(resultArray);
          setIsFirstTime(false);
        }
        setIsLoading(false)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchNewspapers();
  }, []); // Empty dependency array means this effect runs once after the component mounts

  useEffect(() => {
    const mergedArray = newspaperData
      .map((dataItem) => {
        const matchingCountry = allFlags.find(
          (countryItem) => countryItem.countryName === dataItem.country
        );

        if (matchingCountry) {
          return {
            country: dataItem.country,
            flagLogo: matchingCountry.flagLogo,
            positive: dataItem.positive,
            negative: dataItem.negative,
            neutral: dataItem.neutral,
            newspaper_name: dataItem.newspaper_name,
            newspaper_id: dataItem.newspaper_id,
            monthly_readers: dataItem.monthly_readers,
            link: dataItem.link,
            logo: dataItem.logo,
            month: dataItem.month,
          };
        }

        return null; // If no match found
      })
      .filter((item) => item !== null)
      .sort((a, b) => {
        return a.newspaper_name.localeCompare(b.newspaper_name);
      });

    setAllNewspaperData(mergedArray);
  }, [newspaperData, allFlags]);


  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    width: "100%",
    height: "100%",
    // Add other styles as needed
  };
  //----------------------------sort Alphabetically newspaper--------------------
  const sortAlphabeticallyNewspaper = () => {
    setClickCountNews(!clickCountNews);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if (clickCountNews) {
      sortedData = [...allNewspaperData].sort((a, b) => {
        return a.newspaper_name.localeCompare(b.newspaper_name);
      });
    } else {
      sortedData = [...allNewspaperData].sort((a, b) => {
        return b.newspaper_name.localeCompare(a.newspaper_name);
      });
    }

    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
/*
  const sortAlphabeticallyNewspaperAZ = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        return a.newspaper_name.localeCompare(b.newspaper_name);
      })
    setAllNewspaperData(sortedData);
  };

  const sortAlphabeticallyNewspaperZA = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        return b.newspaper_name.localeCompare(a.newspaper_name);
      })
    setAllNewspaperData(sortedData);
  };
  */

  //----------------------------sort Alphabetically Country--------------------
  const sortAlphabeticallyCountry = () => {
    setClickCountCountry(!clickCountCountry);
    setClickCountNews(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountCountry){
       sortedData = [...allNewspaperData].sort((a, b) => {
        return a.country.localeCompare(b.country);
      });
    }else{
       sortedData = [...allNewspaperData].sort((a, b) => {
        return b.country.localeCompare(a.country);
      });
    }
    

    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
  
  //----------------------------sort Total--------------------
  const sortDataByTotal = () => {
    setClickCountTotal(!clickCountTotal);
    setClickCountNews(true);
    setClickCountCountry(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountTotal){
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalB - totalA; // Sort in descending order
      });
    }else{
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalA - totalB; // Sort in descending order
      });
    }

    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
  /*
  const sortDataByTotalLH = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalA - totalB; // Sort in descending order
      })
    setAllNewspaperData(sortedData);
  };

  const sortDataByTotalHL = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalB - totalA; // Sort in descending order
      })
    setAllNewspaperData(sortedData);
  };*/

  //----------------------------sort Positive--------------------
  const sortDataByPositive = () => {
    setClickCountPosite(!clickCountPositive);
    setClickCountNews(true);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountPositive){
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in dscending order
      });
    }
    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
  /*
  const sortDataByPositiveLH = () => {

    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };

  const sortDataByPositiveHL = () => {

    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };
  */

  //----------------------------sort Negative--------------------
  const sortDataByNegative = () => {
    setClickCountNegative(!clickCountNegative);
    setClickCountNews(true);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);

    let sortedData;
    if(clickCountNegative){
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in descending order
      });
    }
    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
/*
  const sortDataByNegativeLH = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };

  const sortDataByNegativeHL = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };
  */
  
  //----------------------------sort Neutral--------------------
  const sortDataByNeutral = () => {
    setClickCountNeutral(!clickCountNeutral);
    setClickCountNews(true);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountNeutral){
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in desscending order
      });
    }
    setAllNewspaperData(sortedData);
    setPageValue(1);
    setFirstNewspaperNumber(0);
    setLastNewspaperNumber(25);
    if (increaseNumber !== 25)
      setIncreaseNumber(25);
  };
/*
  const sortDataByNeutralLH = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };

  const sortDataByNeutralHL = () => {
    let sortedData = [...allNewspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllNewspaperData(sortedData);
  };
*/
  return (
    <div id="" style={containerStyle} className="font-custom">
      <Navbar />
      {isLaptop && (
        <div className="">
          <div className="flex pt-2 min-h-screen">
            <div className="m-8 flex min-h-screen rounded-2xl border border-gray-600 w-full mt-20">
              <div className="p-5 pt-2 w-full">
                <div className="flex mb-5 mt-2 justify-between">
                  <div className="w-1/2">
                    <h2 className="text-[25px] w-full mb-2 font-bold">
                      Newspapers ranked by their perception of India
                    </h2>
                    <div className="flex space-x-3">
                      <div className=" flex font-bold space-x-3">
                        <button className={pageValue == 1 ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={decreaseValue}>{"<"}</button>
                        <h1 className="self-center">{pageValue+" / "+Math.round(allNewspaperData.length/25)}</h1>
                        <button className={pageValue == Math.round(allNewspaperData.length/25) ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={increaseValue}>{">"}</button>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-20 w-1/2 inline-flex rounded-2xl shadow-2xl border border-black-800 backdrop-blur-[3px] bg-white bg-opacity-30 p-0 justify-between">
                    <div className="pb-7 pt-3 px-5 w-5/6">
                      <div className="ml-2 mt-2">
                        <Slider
                          min={0}
                          max={7}
                          marks={sliderMarks}
                          step={1}
                          value={isAllTime ? null :selectedMonth}
                          onChange={handleSliderChange} 
                          railStyle={{ backgroundColor: "black" }}
                          trackStyle={{ backgroundColor: "black" }}
                          handleStyle={isAllTime ? null :{
                            borderColor: "black",

                            width: 10,
                            height: 10,
                            marginTop: -2,
                            borderRadius: 4,
                            backgroundColor: "black",
                            border: "2px solid black",
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-center w-1/6">
                      <button
                        onClick={allTimeData}
                        className={`${isAllTime ? "font-bold" : ""} bg-black border-2 border-black text-white text-sm rounded-xl px-3 py-2  mr-2 w-30`}
                      >
                        All Time
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bg-white bg-opacity-30 backdrop-blur-[3px] shadow-xl border border-gray-300 rounded-2xl p-10 flex min-h-[85%]">
                <div className="w-full">
                  <div className="flex mb-4 justify-between">

                    <div className="w-1/5">
                      <button className="flex px-5 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortAlphabeticallyNewspaper}>
                        <div className="font-semibold ">
                          Newspaper
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="w-1/6 flex justify-center">
                      <button className="flex px-5 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortAlphabeticallyCountry}>
                        <div className="font-semibold ">
                          Country
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="w-1/6 flex justify-center">
                      <button className="flex px-2 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByTotal}>
                        <div className="font-semibold ">
                          Articles Published
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="flex justify-around w-1/2 ">

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-red bg-custom-red bg-opacity-0 hover:bg-opacity-30"  onClick={sortDataByNegative}>
                        <div className="cursor-pointer font-semibold">
                          Negative
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-yellow2 bg-custom-yellow2 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByNeutral}>
                        <div className="cursor-pointer font-semibold">
                          Neutral
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-green bg-custom-green bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByPositive}>
                        <div className="cursor-pointer ml-3 font-semibold">
                          Positive
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                    </div>
                  </div>

                  <hr className="border-t-1 border-black w-full" />

                  {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "2",
                      }}
                    >
                      <div className="spinner-container mt-10">
                        <BarLoader color="black" width={200} />
                      </div>
                    </div>
                  )}
                  {!isLoading && (
                    <div>
                      {allNewspaperData.slice(firstNewspaperNumber,lastNewspaperNumber).map((newspaper, index) => (
                        <a
                          className="hover:cursor-pointer"
                          key={index}
                          onMouseDown={() => handleClick(newspaper)}
                          href="/newspaper-detail"
                        >
                          <div className="flex  transform hover:scale-105 m-2 p-1  hover:shadow-2xl  hover:rounded-lg  hover:transition-transform transition hover:duration-500 ">
                            <div className="flex w-1/5">
                              <div className="self-center mr-2 w-2/3 overflow-hidden">
                                {newspaper.logo && (
                                  <img
                                    src={newspaper.logo}
                                    alt={"Logo: "+newspaper.newspaper_name}
                                    className="h-10"
                                  />
                                ) ||
                                <h2 className="text-sm self-center font-semibold">
                                {newspaper.newspaper_name}
                                </h2>
                                }
                              </div>
                            </div>

                            <div className="flex w-1/6 justify-center">
                            {newspaper.flagLogo && (
                              <div className="self-center shadow-sm shadow-black rounded-lg overflow-hidden">
                                <img
                                  src={newspaper.flagLogo}
                                  alt="Flag"
                                  className="h-10 w-15"
                                />
                              </div>
                            ) || (
                              <h2 className="text-sm self-center font-bold">
                                {newspaper.country}
                              </h2>
                            )}
                            </div>

                            <div className="flex w-1/6 justify-center">
                              <h2 className="text-sm self-center font-semibold mr-1">
                              {newspaper.positive +
                                newspaper.negative +
                                newspaper.neutral}
                              </h2>
                              <div className="self-center mr-2 overflow-hidden">
                              <img
                                src={articleIcon}
                                alt="Article Icon"
                                className="w-[25px] h-[25px]"
                              />
                              </div>
                            </div>

                            <div className="self-center w-1/2">
                              {newspaper.negative === 0 &&
                              newspaper.positive === 0 &&
                              newspaper.neutral === 0 ? (
                                <div className="flex h-full">
                                  <div className="invisible">t Enough Data</div>
                                  {/* <div className="invisible">Not Enough Data</div> */}
                                  <div className="self-center">Not Enough Data</div>
                                </div>
                              ) : (
                                <BigSingleHorizontalBar
                                  key={newspaper.newspaper_name}
                                  positiveValue={newspaper.positive}
                                  negativeValue={newspaper.negative}
                                  neutralValue={newspaper.neutral}
                                />
                              )}
                            </div>
                            {/* <div className="p-0 ml-20 w-auto ">Map Area</div> */}
                          </div>
                          <hr className="border-t-1 border-black w-full" />
                        </a>
                      ))}
                    </div>
                  )}
                </div>
                </div>
                <div className="bg-opacity-0 backdrop-blur-[3px] shadow-xl border border-gray-300 rounded-2xl p-2 font-bold flex justify-center space-x-3 mt-4">
                  <button className={pageValue == 1 ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={decreaseValue}>{"<"}</button>
                  <h1 className="self-center">{pageValue+" / "+Math.round(allNewspaperData.length/25)}</h1>
                  <button className={pageValue == Math.round(allNewspaperData.length/25) ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={increaseValue}>{">"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          <div className="py-6 invisible">Hidden Text Area</div>
          <div className="p-2 mt-2 p-1 w-full">
            <div className=" my-1">
              <h2 className="text-xl text-center text-gray-600 font-bold mb-4">
                Newspapers ranked by their perception of India
              </h2>
            </div>

            <div className="mb-2 w-full inline-flex rounded-2xl border border-black-800 bg-white p-0 justify-between">
              <div className="mx-1 px-2 pt-2 w-full overflow-x-scroll">
                <div className=" w-500">
                  <div
                    className=" w-full max-w-full px-2"// overflow-x-scroll "
                    style={{ maxWidth: "370px" }}
                  >
                    <Slider
                      min={0}
                      max={7}
                      marks={sliderMarks}
                      step={1}
                      value={isAllTime ? null :selectedMonth}
                      onChange={handleSliderChange}
                      railStyle={{ backgroundColor: "black" }}
                      trackStyle={{ backgroundColor: "black" }}
                      handleStyle={isAllTime ? null :{
                        borderColor: "black",
                        width: 10,
                        height: 10,
                        marginTop: -2,
                        borderRadius: 4,
                        backgroundColor: "black",
                        border: "2px solid black",
                      }}
                    />
                    <div className="invisible">{selectedMonth}</div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  onClick={allTimeData}
                  className={`${isAllTime ? "font-bold" : ""} bg-black border-2 border-black text-white rounded-xl p-1 my-2 mr-1 w-20`}
                >
                  All Time
                </button>
              </div>
            </div>

            {/*<div className="mb-5 w-full inline-flex rounded-2xl border border-black-800 bg-white p-0 justify-between">
              <select className="w-full" value={selectedSort} onChange={handleSortChange}>
                <option value="DEFAULT">Sort by ...</option>
                <option value="NAZ">Newspaper A-Z</option>
                <option value="NZA">Newspaper Z-A</option>
                <option value="ALH">Articles Published Low-Hight</option>
                <option value="AHL">Articles Published Hight-Low</option>
                <option value="NEGLH">Negative Low-Hight</option>
                <option value="NEGHL">Negative Hight-Low</option>
                <option value="NEULH">Neutral Low-Hight</option>
                <option value="NEUHL">Neutral Hight-Low</option>
                <option value="POSLH">Positive Low-Hight</option>
                <option value="POSHL">Positive Hight-Low</option>
              </select>
            </div>*/}

            <div className="mt-1 items-center min-h-screen">
              <div className="flex justify-between ">
              <div className="flex w-1/3 px-1 justify-center" onClick={sortAlphabeticallyNewspaper}>
                  <div className="self-end">
                    <div className="flex text-sm pb-1">Newspaper<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-gray-500 w-full" />
                  </div>
                </div>

                <div className="flex w-1/3 px-1 justify-center " onClick={sortDataByTotal}>
                  <div className="self-end">
                    <div className="flex text-xs pb-1">Articles pub.<img  src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-gray-500 w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByNegative}>
                  <div>
                    <div className="flex">Neg.<img  src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-red w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByNeutral}>
                  <div>
                    <div className="flex">Neu.<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-yellow2 w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByPositive}>
                  <div>
                    <div className="flex">Pos.<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-green w-full" />
                  </div>
                </div>
              </div>
              <hr className="border-t-1 mt-3 mb-3 border-black w-full" />

              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "2",
                  }}
                >
                  <div className="spinner-container mt-10">
                    <BarLoader color="black" width={100} />
                  </div>
                </div>
              )}
              {!isLoading && (
                <div>
                  {allNewspaperData.slice(firstNewspaperNumber,lastNewspaperNumber).map((newspaper, index) => (
                    <a className="" onMouseDown={() => handleClick(newspaper) } href="/newspaper-detail">
                    <div>
                    {newspaper.positive+newspaper.negative+newspaper.neutral === 0 ? (                      
                      <div key={index} className="flex justify-between border backdrop-blur-[3px] border-gray-300 bg-opacity-40 bg-gray-200 rounded-lg shadow-lg ">
                        <div className="w-1/4">
                        {newspaper.logo && (
                          <img
                            src={newspaper.logo}
                            alt={"Logo: "+newspaper.newspaper_name}
                            className="h-10"
                          />
                        ) ||
                          <h2 className="w-2/3 self-center font-semibold">
                            {newspaper.newspaper_name}
                          </h2>
                        }
                        </div>
                        <div className="w-2/3 self-center">
                          <div className="flex justify-center">Not Enough Data</div>
                        </div>
                      </div>
                      ) : (
                      <div key={index} className="flex p-1 justify-between border backdrop-blur-[3px] border-gray-300 bg-opacity-40 bg-gray-200 rounded-lg shadow-lg ">
                        <div className="w-1/3">
                          {newspaper.logo && (
                            <div className="flex justify-center">
                            <img
                              src={newspaper.logo}
                              alt={"Logo: "+newspaper.newspaper_name}
                              className="h-10 my-1 "
                            />
                            </div>
                          )}
                        </div>
                        {/* <h2 className=" mt-3">{newspaper.newspaper_name}</h2>
                         */}

                        <div className="flex w-1/3 justify-center">
                          <div className="self-center">
                          <img
                            src={articleIcon}
                            alt="Article Icon"
                            className="w-[25px] h-[25px]"
                          />
                          <p className="text-sm text-center mt-px">{newspaper.positive+newspaper.negative+newspaper.neutral}</p>
                          </div>
                        </div>

                        <div className="flex w-1/5 justify-center">
                          <div className="self-center">
                            <MicroPieChart
                              key={newspaper.newspaper_name}
                              hoveredPositive={Math.floor((newspaper.negative/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              hoveredNegative={100 - Math.floor((newspaper.negative/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              fillType="negative"
                            />
                            <p className="text-sm">{newspaper.positive+newspaper.negative+newspaper.neutral === 0 ? 0
                                                              :Math.floor((newspaper.negative/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}%</p>
                          </div>
                        </div>

                        <div className="flex w-1/5 justify-center">
                          <div className="self-center">
                            <MicroPieChart
                              key={newspaper.newspaper_name}
                              hoveredPositive={Math.floor((newspaper.neutral/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              hoveredNegative={100 - Math.floor((newspaper.neutral/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              fillType="neutral"
                            />
                            <p className="text-sm">{newspaper.positive+newspaper.negative+newspaper.neutral === 0 ? 0
                                                              :Math.floor((newspaper.neutral/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}%</p>
                          </div>
                        </div>

                        <div className="flex w-1/5 justify-center">
                          <div className="self-center">
                            <MicroPieChart
                              key={newspaper.newspaper_name}
                              hoveredPositive={Math.floor((newspaper.positive/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              hoveredNegative={100 - Math.floor((newspaper.positive/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}
                              fillType="positive"
                            />
                            <p className="text-sm">{newspaper.positive+newspaper.negative+newspaper.neutral === 0 ? 0
                                                              : Math.floor((newspaper.positive/(newspaper.positive+newspaper.negative+newspaper.neutral))*100)}%</p>
                          </div>
                        </div>
                      </div>
                      )}
                      <hr className="border-t-1 mt-3 mb-3 border-black w-full" />
                      </div>
                    </a>
                  ))}
                  <div className="p-2 font-bold flex justify-center space-x-3 ">
                    <button className={pageValue == 1 ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={decreaseValue}>{"<"}</button>
                    <h1 className="self-center">{pageValue+" / "+Math.round(allNewspaperData.length/25)}</h1>
                    <button className={pageValue == Math.round(allNewspaperData.length/25) ? "bg-black text-gray-500 rounded-lg px-3 py-1" : "bg-black text-white rounded-lg px-3 py-1"} onClick={increaseValue}>{">"}</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewspaperView;
