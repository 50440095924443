import React from 'react';

const MySvgComponent = ({ stroke }) => (


<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1416 16.5093L16.4484 15.1521L12.2861 8.69312L10.4307 9.79894L14.1165 16.5093H14.1416ZM5.61652 18.1931L7.94838 19.5L11.5339 12.7143L9.65339 11.6587L5.64159 18.1931H5.61652ZM0 11.6587V14.3228L7.69764 13.996L7.64749 11.8598L0 11.6336V11.6587ZM17 8.34127L16.9749 5.67725L9.27729 6.00397V8.16534L16.9749 8.34127H17ZM2.80826 3.44048L0.526549 4.79762L4.63864 11.2817L6.46903 10.2011L2.80826 3.44048ZM11.3083 1.83201L9.00147 0.5L5.441 7.28571L7.27139 8.3664L11.2832 1.83201H11.3083Z" fill={stroke}/>
</svg>




);

export default MySvgComponent;
