import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import backgroundImage from "../assets/backgroundMain.jpg";

const AboutUs = () => {

  const questionText = [
    "Why Kutniti.watch?",
    "Is Kutniti.watch linked to Kutniti Foundation?",
    "Where are you based?",
    "When did you start?",
    "Who funds you?",
    "Can I donate?",
    "Are you linked to any organisation?",
    "Why are you in beta?",
  ]
  
  const answerText = [
    "We think it is a fundamental right, for the citizens of India, to know, in the most neutral possible way, what the rest of the world writes about the country.\n"+
    "It is also a way to see if there is a disparity between what happens in India and what the rest of the world thinks about it.\n"+
    "And if we want to improve the perception of India, the first thing to do is to measure it!",

    "Yes. Kutniti.watch is a service developed by Kutniti Foundation.\n"+
    "In fact, it's the only public operation so far from the Kutniti Foundation. The tip of the iceberg.\n"+
    "If you want to know more about Kutniti Foundation: Kutniti.org",

    "Delhi, India.",

    "Early 2015. A lot of people asked us to make the information public to everyone so in 2023 we decided to publish everything online.",

    "Kutniti.watch is totally funded by Kutniti which is 100% funded by its founders.",

    "It is very kind of you, but we don't accept any external funding. I'm sure there are plenty of great causes you can fund.",

    "No, we are a standalone organisation. We are not linked in any way to the Indian government. We are also apolitic.",

    "Because the database is not fully uploaded. And data speaks better after a certain volume. We will launch the final version when we have a much bigger database. In the meantime, we still make it public for the curious people and to help us find bugs.",
  ]



  const initialQuestions = [
    { id: 1, isVisible: false },
    { id: 2, isVisible: false },
    { id: 3, isVisible: false },
    { id: 4, isVisible: false },
    { id: 5, isVisible: false },
    { id: 6, isVisible: false },
    { id: 7, isVisible: false },
    { id: 8, isVisible: false },
    { id: 9, isVisible: false },
    // Add more questions as needed
  ];

const [questions, setQuestions] = useState(initialQuestions);

  const toggleAnswer = (id) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === id ? { ...question, isVisible: !question.isVisible } : question
      )
    );
  };

  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    width: "100%",
    height: "100%",
    // Add other styles as needed
  };





  
  return (
    <div id="" style={containerStyle} className="w-full font-custom">
      <Navbar />
      <div className=" p-1 lg:p-2 pb-5">
        <h1 className="font-bold text-3xl p-4 lg:p-6 invisible">
          Providing Free spacing
        </h1>
        <div className="font-bold text-3xl lg:text-5xl mt-2 lg:mt-0 p-1 lg:p-5 w-full text-center">
          About Kutniti Watch
        </div>
        <div className="px-0 lg:px-2">
              {/* Question 1 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(1)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[0].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[0]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[0].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[0].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[0]}</p>
            </div>
          </div>
        </div>

        {/* Question 2 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(2)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[1].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[1]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[1].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[1].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[1]}</p>
            </div>
          </div>
        </div>

     {/* Question 3 */}
     <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(3)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[2].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[2]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[2].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[2].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[2]}</p>
            </div>
          </div>
        </div>

        {/* Question 4 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(4)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[3].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[3]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[3].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[3].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[3]}</p>
            </div>
          </div>
        </div>

                      {/* Question 5 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(5)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[4].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[4]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[4].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[4].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[4]}</p>
            </div>
          </div>
        </div>

        {/* Question 6 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(6)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[5].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[5]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[5].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[5].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[5]}</p>
            </div>
          </div>
        </div>

     {/* Question 7 */}
     <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(7)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[6].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[6]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[6].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[6].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[6]}</p>
            </div>
          </div>
        </div>

        {/* Question 8 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(8)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[7].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[7]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[7].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[7].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[7]}</p>
            </div>
          </div>
        </div>

        </div>
        <div className="text-sm font-bold text-gray-500 w-full p-4 text-center">
          Kutniti Foundation {new Date().getFullYear()}
        </div>  
      </div>
    </div>
    

  );
};

export default AboutUs;
