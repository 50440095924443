import React from "react";

const NewspaperReaderCard = ({ firstValue }) => {

  let dispString;

  if (firstValue > 1000000000){
    dispString = firstValue/1000000000 + "B"
  }
  else if (firstValue > 1000000){
    dispString = firstValue/1000000 + "M"
  }
  else dispString = firstValue
  

  return (
    <div className="bg-opacity-0 backdrop-blur-[2px] w-full h-full rounded-lg shadow-2xl border p-2 pt-1 flex">
      <div className="self-center w-full">
        <div className="flex text-center justify-center mb-2 text-sm">Monthly Readers</div>
        <div className=" text-2xl text-center font-bold mb-2 mt-3">{dispString}</div>
      </div>
    </div>
  );
};

export default NewspaperReaderCard;
