import React from 'react';

const MySvgComponent = ({ stroke }) => (


<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0606 5.72838C13.6984 3.56364 11.8876 1.74805 9.64212 1.32907C7.97614 0.979913 6.23773 1.3989 4.93392 2.44635C3.55767 3.49381 2.83333 5.03008 2.83333 6.63618C2.83333 8.10262 3.4128 9.42939 4.49931 10.4769C5.00635 10.9657 5.29609 11.5243 5.44095 12.1528L5.58582 13.0606C5.80312 13.9684 6.5999 14.6667 7.61397 14.6667H9.35239C10.294 14.6667 11.1632 13.9684 11.3805 13.0606L11.5978 12.1528C11.7427 11.5243 12.0324 10.9657 12.5395 10.4769C13.8433 9.2199 14.4228 7.47414 14.0606 5.72838ZM11.5254 9.49923C10.8011 10.1975 10.3665 11.0355 10.1492 11.8735L9.93186 12.7813C9.93186 13.0606 9.64212 13.2701 9.35239 13.2701H7.61397C7.32424 13.2701 7.10694 13.0606 7.0345 12.7813L6.8172 11.8735C6.67233 10.9657 6.16529 10.1975 5.44095 9.49923C4.71661 8.73109 4.28201 7.75346 4.28201 6.63618C4.28201 5.44906 4.86148 4.26194 5.80312 3.49381C6.5999 2.93517 7.54154 2.58601 8.48318 2.58601C8.77292 2.58601 9.06265 2.58601 9.35239 2.65584C11.0184 3.005 12.3222 4.33177 12.6119 5.93787C12.9017 7.26465 12.467 8.59143 11.5254 9.49923Z" fill={stroke}/>
<path d="M10.5022 15.375H6.42222C5.96889 15.375 5.66666 15.6583 5.66666 16.0833C5.66666 16.5083 5.96889 16.7917 6.42222 16.7917H10.5778C11.0311 16.7917 11.3333 16.5083 11.3333 16.0833C11.3333 15.6583 10.9556 15.375 10.5022 15.375Z" fill={stroke}/>
</svg>



);

export default MySvgComponent;
