import React, { PureComponent } from 'react';
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';



export default class CustomAreaChart extends PureComponent {
  render() {
    let { chartData } = this.props;


    let maxPossibleSum = 0;
chartData.forEach(entry => {
  const sum = entry.pos + entry.neg + entry.neu;
  if (sum > maxPossibleSum) {
    maxPossibleSum = sum;
  }
});

// Update max values for entries with all 0 pos, neg, neu values
chartData.forEach(entry => {
  if (entry.neg === 0 && entry.pos === 0 && entry.neu === 0) {
    entry.max = maxPossibleSum;
  }
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let pldpos = payload.at(0);
    let pldneu = payload.at(1);
    let pldneg = payload.at(2);
    return (
      <div className="custom-tooltip bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 rounded-lg shadow-xl p-1">
        <p className="label text-center font-bold">{`${label}`}</p>
        <div className='space-y-1'>
          <div className='rounded-lg p-1' style={{ backgroundColor: pldneg.fill }}>
            <div className='text-xs text-center font-bold'>{pldneg.value}</div>
            <div className='text-xs text-center'>{pldneg.dataKey === "neg" ? "Negative" : (pldneg.dataKey === "neu" ? "Neutral" : "Positive")}</div>
          </div>
          <div className='rounded-lg p-1' style={{ backgroundColor: pldneu.fill }}>
            <div className='text-xs text-center font-bold'>{pldneu.value}</div>
            <div className='text-xs text-center'>{pldneu.dataKey === "neg" ? "Negative" : (pldneu.dataKey === "neu" ? "Neutral" : "Positive")}</div>
          </div>
          <div className='rounded-lg p-1' style={{ backgroundColor: pldpos.fill }}>
            <div className='text-xs text-center font-bold'>{pldpos.value}</div>
            <div className='text-xs text-center'>{pldpos.dataKey === "neg" ? "Negative" : (pldpos.dataKey === "neu" ? "Neutral" : "Positive")}</div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={chartData}
          margin={{
            top: 30,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          {/* <YAxis domain={[0, 100]}/> */}
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
          {/* <Legend /> */}
          <Area type="monotone" dataKey="neg" stackId="a" stroke="#ee334d" fill="#ee334d" />
          <Area type="monotone" dataKey="neu" stackId="a" stroke="#fac80c" fill="#fac80c" />
          <Area type="monotone" dataKey="pos" stackId="a" stroke="#74bf50" fill="#74bf50" />
          {/* <Area type="monotone" dataKey="max" stackId="a" stroke="#d6cec3" fill="#d6cec3" /> */}
        </AreaChart>
        </ResponsiveContainer>
    );
  }
}
