import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../components/Navbar";

import backgroundImage from "../assets/backgroundMain.jpg";

const Methodology = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });

  const questionText = [
    "Why these 13 countries?",
    "Which countries will be added in the future?",
    "How do you select the media representing a country?",
    "How do you select the articles from these media?",
    "How do you moderate the article? By Human or by AI?",
    "Do you speak all the languages in which the articles are published?",
    "How do you decide if an article is positive, neutral or negative?",
    "I don't agree with the tag you gave to an article, will you correct it?",
    "Why is the perception of India so bad?",
  ]
  
  const answerText = [
    "These countries have been handpicked by our team by scrutinising prevailing " +
    "trends in world dynamics. We believe that it is crucial to closely observe both, " +
    "our closest diplomatic partners as well as perpetual foes. Integrating the news " +
    "articles published in the P5 nations in our pool of data goes without saying. To " +
    "elaborate, China houses 17.72% of the world's population and has a strong " +
    "presence in Asia, France has influence in Africa and the Middle-East, Russia in " +
    "Eastern Europe and Central Asia, the UK in Europe and Africa, while the US " +
    "has an influence worldwide. Additionally, we have made sure to incorporate at " +
    "least one powerful nation from each continent which might have a potential or " +
    "show a tendency to influence narratives of the majority of the mainland, such as " +
    "Brazil in Latin America. Japan was chosen because of its long history of Special " +
    "Strategic and Global Partnership with India. Other countries like Australia, " +
    "Canada, UAE and Singapore made it to the website as they are a home to the " +
    "huge Indian diaspora.",

    "Strong candidates are: Bangladesh, Germany, Egypt, Indonesia, Iran, Israel, Mexico, Saudi Arabia, South Africa, South Korea, Sri Lanka, Taiwan, Turkey, Vietnam \n\n If you want to see a country in particular, please let us know on X [LINK]",

    "We select the media with the biggest amount of readers. We ensure that this set of media represents at least 85% of the readership of the country. It's the minimum to reflect the sentiment of the press of a country.",

    "We extract from these medias all the articles mentioning certain keywords like \"India\", \"Indian\", \"Bharat\", \"New Delhi\"....\n\n\n"+
    "From this list we exclude:\n\n"+
    "- The articles not talking about India. A lot of false positives are about \"Indian Hemp\", \"Indians\" used for Native Americans, people named \"India\"...\n\n"+
    "- The articles where India plays a minor role. Many articles talk about a list of countries where India is just one among many. For example there is a reference to India but also 19 others country in the list of top food exporters of the world. But the article doesn't say anything about India in particular\n\n"+
    "- The articles talking about sports results. If an article just give the result of a badminton match between India and Germany we don't consider it.\n\n"+
    "Bollywood gossips\n"+
    "Stock market",

    "Every single article is reviewed by the moderator. We don’t use AI because AI algorithms often fail to understand the tone of the writer (sarcasm, double sense, irony tone) and underlying connotations of the text.",

    "We have a big team of moderators speaking many languages. But unfortunately none of them speak Russian or Portuguese so we have to translate them.\n\n"+
    "We use a professional translation software to minimize the risk of bad interpretation due to the translation.",

    "It is very easy, we ask ourselves the question: \"After reading this article, is my perception of India better, same or worst?\".\n\n"+
    "In very few cases it is very difficult to define the perception, so we mark it as Neutral.\n\n"+
    "Remember that we measure perception.\n\n"+
    "We don't judge if the article is:\n\n"+
    "- Well written or not\n\n"+
    "- Biassed or not\n\n"+
    "- Contain fake news or not\n"+
    "...\n\n"+
    "What we measure is how is India portrayed in foreign media.",

    "We spend thousands of hours reviewing thousands of articles so it is totally possible that we make a mistake.\n"+
    "In that case we are sorry and will correct it after verification.\n"+
    "Just send us the name of the article [HERE]\n"+
    "The change will be reflected in 48 hours.",

    "Kutniti.watch is an open DATA webtool and we are impartial when we review articles.\n"+
    "If a good article is published it will reflect in our platform, same for a bad one.\n\n"+
    "But we are giving some macro analyses on our Youtube chanel: LINK]",
  ]



  const initialQuestions = [
    { id: 1, isVisible: false },
    { id: 2, isVisible: false },
    { id: 3, isVisible: false },
    { id: 4, isVisible: false },
    { id: 5, isVisible: false },
    { id: 6, isVisible: false },
    { id: 7, isVisible: false },
    { id: 8, isVisible: false },
    { id: 9, isVisible: false },
    // Add more questions as needed
  ];
  
  const [questions, setQuestions] = useState(initialQuestions);
  
    const toggleAnswer = (id) => {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === id ? { ...question, isVisible: !question.isVisible } : question
        )
      );
    };


  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    width: "100%",
    height: "100%",
    // Add other styles as needed
  };





  
  return (
    <div id="" style={containerStyle} className="w-full font-custom">
      <Navbar />
      <div className=" p-1 lg:p-2 pb-5">
        <h1 className="font-bold text-3xl p-4 lg:p-6 invisible">
          Providing Free spacing
        </h1>
        <div className="font-bold text-3xl lg:text-5xl mt-2 lg:mt-0 p-1 lg:p-5 w-full text-center">
        Methodology
        </div>
        <div className="font-bold text-sm lg:text-xl p-1 lg:p-2 w-full text-center">
        Kutniti.Watch is an impartial Open DATA tool developed by Kutniti Foundation. It measures the perception of India in foreign media.
        </div>
        <div className="px-0 lg:px-2">
              {/* Question 1 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(1)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[0].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[0]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[0].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[0].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[0]}</p>
            </div>
          </div>
        </div>

        {/* Question 2 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(2)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[1].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 backdrop-blur-[3px] bg-opacity-90 bg-white"
            >
              <h2 className="text-lg font-bold">{questionText[1]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[1].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[1].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[1]}</p>
            </div>
          </div>
        </div>

     {/* Question 3 */}
     <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(3)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[2].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[2]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[2].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[2].isVisible ? "" : "hidden"} p-4`}>
            <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[2]}</p>
            </div>
          </div>
        </div>

        {/* Question 4 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(4)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[3].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[3]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[3].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[3].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[3]}</p>
            </div>
          </div>
        </div>

                      {/* Question 5 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(5)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[4].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[4]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[4].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[4].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[4]}</p>
            </div>
          </div>
        </div>

        {/* Question 6 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(6)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[5].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[5]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[5].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[5].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[5]}</p>
            </div>
          </div>
        </div>

     {/* Question 7 */}
     <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(7)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[6].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[6]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[6].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[6].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[6]}</p>
            </div>
          </div>
        </div>

        {/* Question 8 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(8)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[7].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[7]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[7].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[7].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[7]}</p>
            </div>
          </div>
        </div>

        {/* Question 9 */}
        <div className="border-2 mx-5 my-4 backdrop-blur-[3px] rounded-lg bg-opacity-20 bg-gray-400 shadow-md border-gray-200 cursor-pointer" onClick={() => toggleAnswer(9)}>
          <div class="relative group">
            <div
                class={`answer ${!questions[8].isVisible ? "absolute transitiona-all duration-1000 opacity-0 -inset-px bg-gray-300 rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt": "hidden"}`}>
            </div>
            <div
              className="relative flex justify-between items-center cursor-pointer rounded-lg p-4 bg-white backdrop-blur-[3px] bg-opacity-90"
            >
              <h2 className="text-lg font-bold">{questionText[8]}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`h-4 w-4 ${
                  questions[8].isVisible ? "rotate-180" : ""
                }`}
              >
                <path
                  stroke-linecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>
            <div className={`answer ${questions[8].isVisible ? "" : "hidden"} p-4`}>
              <p className="" style={{whiteSpace: 'pre-line'}}>{answerText[8]}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

  );
};

export default Methodology;
