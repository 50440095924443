import React from 'react';
import ReactApexChart from 'react-apexcharts';


class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        series: [
            { name: 'Negative', data: [props.negativeValue], color: '#ee334d' }, // Change the color here
            { name: 'Neutral', data: [props.neutralValue], color: '#fac80c' }, 
            { name: 'Positive', data: [props.positiveValue], color: '#74bf50' }, // Change the color here
 
          ],
      options: {
        grid: {
            show: false, // Hide the grid lines
          },
        chart: {
          type: 'bar',
          width: '100%',
          height: 50, // Set height as needed
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false, // Hide chart toolbar
          },
        },
        plotOptions: {
          bar: {
            offsetY: -5,
            horizontal: true,
            dataLabels: {
              position: 'center', // Display data labels in the center of bars
            },
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            colors: ['#fff'], // Data label color
          },
        },
        legend: {
          show: false, // Hide the legend
        },
        xaxis: {
          labels: {
            show: false, // Hide x-axis labels
          },
          axisBorder: {
            show: false, // Hide x-axis border
          },
        },
        yaxis: {
          labels: {
            show: false, // Hide y-axis labels
          },
          axisBorder: {
            show: false, // Hide y-axis border
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: false,
          followCursor: true,
          intersect: true,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          onDatasetHover: {
              highlightDataSeries: false,
          },
          x: {
              show: false,
              format: 'dd MMM',
              formatter: undefined,
          },
          y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                return value+" articles"
              },
              title: {
                  formatter: (seriesName) => seriesName+":",
              },
          },
          z: {
              formatter: undefined,
              title: 'Size: '
          },
          marker: {
              show: false,
          },
          items: {
             display: 'block',
          },
          fixed: {
              enabled: false,
              position: 'topRight',
              offsetX: 0,
              offsetY: 0,
          },
        }
      },
    };
  }

  render() {
    return (
        <div id="chart" style={{ width: '100%', marginTop: '-30px', marginBottom: '-20px' }}>
        <ReactApexChart
          options={this.state.options}
          series={[    
            { name: 'Negative', data: [this.props.negativeValue], color: '#ee334d' }, // Change the color here
            { name: 'Neutral', data: [this.props.neutralValue], color: '#fac80c' }, 
            { name: 'Positive', data: [this.props.positiveValue], color: '#74bf50' }, // Change the color here
          ]}
          type="bar"
          height={80}
        />
      </div>
    );
  }
}

export default ApexChart;
