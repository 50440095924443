import React from "react";
import DefenseImg from "../assets/countryStats/defense.png";

const CountryDefenseCard = ({ firstValue, secondValue }) => {
  return (
    <div className="bg-opacity-0 backdrop-blur-[2px] shadow-2xl border w-30 h-20 rounded-lg flex flex-col justify-center items-center">
      <div className="flex justify-center items-center">
        <div className="text-black text-md font-bold flex">
          {firstValue}
          <div className="text-black text-xs font-bold">{ (firstValue === 1 ? "st" : (firstValue === 2 ? "nd" : (firstValue === 3 ? "rd" : "th")))}</div>
        </div>
        <img src={DefenseImg} alt="Relation Image" className="w-13 h-8 ml-1" />
      </div>

      <div className="flex justify-center text-center text-gray-600 text-sm">{secondValue}</div>
    </div>
  );
};

export default CountryDefenseCard;
