
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../Styles/Staff.css";
import PieChartComponent from "../graphs/PieChartComponent";
import html2canvas from "html2canvas";
import share from "../assets/shareButton.png";
import copy from "../assets/copy.png";
import check from "../assets/check.png";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import backgroundImage from "../assets/worldMap.jpg";
import AreaChartComponent from "../graphs/AreaChartComponent";
import AreaChartComponentPos from "../graphs/AreaChartComponentWorldPos"
import AreaChartComponentNeu from "../graphs/AreaChartComponentWorldNeu"
import AreaChartComponentNeg from "../graphs/AreaChartComponentWorldNeg"
import SmallBarChartComponent from "../graphs/SmallBarChartComponent";
import BigSingleHorizontalBar from "../graphs/BigSingleHorizontalBar";
import { useMediaQuery } from "react-responsive";
import SmallPieChart from "../graphs/SmallCopyPieChart";
import MicroPieChart from "../graphs/MicroPieChart";
import { all } from "axios";

import fiveEyesImg from "../assets/countryStats/five eyes 1.svg";
import bricsImg from "../assets/countryStats/brics 1.svg";
import nuclearImg from "../assets/countryStats/nuclear 1.svg";
import qsdImg from "../assets/countryStats/qsd 1.svg";
import unscImg from "../assets/countryStats/unsc 1.svg";
import borDispImg from "../assets/countryStats/shield 1.svg";

import articleIcon from "../assets/article-icon.png"


function CountryDetails() {
  const [dataForBar, setDataForBar] = useState([]);
  const [dataForBarPos, setDataForBarPos] = useState([]);
  const [dataForBarNeu, setDataForBarNeu] = useState([]);
  const [dataForBarNeg, setDataForBarNeg] = useState([]);

  const [newspaperData, setNewspaperData] = useState([]);

  const [countryData, setCountryData] = useState({
    name: "",
    all: 0,
    positive: 0,
    negative: 0,
    neutral: 0,
  });
  const [flagObjectSelected, setFlagObjectSelected] = useState("");
  const [isWideScreen, setIsWideScreen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });

  const [isPositive, setIsPositive] = useState(false);
  const [isNeutral, setIsNeutral] = useState(false);
  const [isNegative, setIsNegative] = useState(false);

  const [brics, setBrics] = useState();
  const [fiveEyes, setFiveEyes] = useState();
  const [unsc, setUnsc] = useState();
  const [qsd, setQsd] = useState();
  const [nuclear, setNuclear] = useState();
  const [borDisp, setBorDisp] = useState();

  const [allianceList, setAllianceList] = useState([]);

  const [clickCountCountry, setClickCountCountry] = useState(true);
  const [clickCountTotal, setClickCountTotal] = useState(true);
  const [clickCountPositive, setClickCountPosite] = useState(false);
  const [clickCountNeutral, setClickCountNeutral] = useState(false);
  const [clickCountNegative, setClickCountNegative] = useState(false);

  let tempName;

  useEffect(() => {
    // Update the state based on the window width when the component mounts
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1380);
    };

    // Add an event listener to update the state when the window is resized
    window.addEventListener("resize", handleResize);

    // Initialize the state based on the window width
    handleResize();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    tempName = "World";
    const tempPositive = localStorage.getItem("hoveredPositive");
    const tempNegative = localStorage.getItem("hoveredNegative");
    const tempNeutral = localStorage.getItem("hoveredNeutral");

    async function fetchNewspapers() {
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/newspaper/getAllNewspapers"
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const newArrayOfObjects = data.flatMap((newspaper) =>
          Object.entries(newspaper.articles).map(([month, stats]) => ({
            newspaper_id: newspaper.newspaper_id,
            newspaper_name: newspaper.newspaper_name,
            link: newspaper.link,
            country: newspaper.country,
            logo: newspaper.logo,
            month: monthToNumber(month), // Convert month to number (e.g., "June" to 6)
            positive: stats.Positive || 0, // Set to 0 if not present
            negative: stats.Negative || 0, // Set to 0 if not present
            neutral: stats.Neutral || 0, // Set to 0 if not present
          }))
        );

        function monthToNumber(month) {
          // Map month names to numbers
          const monthMap = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
          };

          return monthMap[month] || 0; // Default to 0 if month is not found
        }

        //---------Now make objects for remaining months with 0s in positive neg and neu---

        const monthlyData = [];

        // Iterate over each object in the original array
        newArrayOfObjects.forEach((item) => {
          // Iterate over 12 months
          for (let month = 1; month <= 12; month++) {
            // Check if the month already exists in the original data
            const existingMonthData = newArrayOfObjects.find(
              (x) => x.newspaper_id === item.newspaper_id && x.month === month
            );

            if (existingMonthData) {
              // If the month exists, push the existing data
              monthlyData.push(existingMonthData);
            } else {
              // If the month does not exist, create a new object with zeros
              monthlyData.push({
                ...item,
                month: month,
                positive: 0,
                negative: 0,
                neutral: 0,
              });
            }
          }
        });
        //----------------------------sTORE ALL MONTHS DATA IN monthwiseData------------------------------------
        // Remove duplicates
        const uniqueMonthlyData = monthlyData.filter((item, index, self) => {
          // Find the index of the first occurrence of the current item
          const firstIndex = self.findIndex(
            (otherItem) =>
              otherItem.newspaper_name === item.newspaper_name &&
              otherItem.month === item.month
          );

          // Keep the current item only if its index is the same as the first index found
          return index === firstIndex;
        });

        const aggregatedData = {};

        uniqueMonthlyData.forEach((data) => {
          const { newspaper_name, positive, negative, neutral, logo, country } =
            data;

          if (!aggregatedData[newspaper_name]) {
            aggregatedData[newspaper_name] = {
              newspaper_name,
              positive,
              negative,
              neutral,
              logo,
              country,
            };
          } else {
            aggregatedData[newspaper_name].positive += positive;
            aggregatedData[newspaper_name].negative += negative;
            aggregatedData[newspaper_name].neutral += neutral;
          }
        });

        // Convert the aggregatedData object back to an array of objects
        const resultArray = Object.values(aggregatedData);

        // ----------------------------Filter only the newspapers with country as tempName---------------------

        setNewspaperData(resultArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchNewspapers();

    setCountryData({
      name: tempName,
      positive: tempPositive,
      negative: tempNegative,
      neutral: tempNeutral,
    });

    const fetchAllCountries = async () => {
      try {

        const response = await fetch(
          "https://api.kutniti.watch/api/country/getallCountryArticlesMonth",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const getData = await response.json();

          const transformedData = [];

          getData.forEach((dataObj) => {
            dataObj.pubDates.forEach((dateStr) => {
              const date = new Date(dateStr);
              const month = date.getMonth() + 1; // Months are 0-based, so we add 1
              const yearMonth = `${date.getFullYear()}-${month}`;

              const existingEntry = transformedData.find(
                (entry) =>
                  entry.countryName === dataObj.country &&
                  entry.type === dataObj.type &&
                  entry.month === yearMonth
              );
              if (existingEntry) {
                existingEntry.numArticles++;
              } else {
                transformedData.push({
                  countryName: dataObj.country,
                  type: dataObj.type,
                  month: yearMonth,
                  numArticles: 1,
                });
              }
            });
          });


          const combinedData = [];

          transformedData.forEach((dataObj) => {
            const { countryName, month, type, numArticles } = dataObj;

            let existingEntry = combinedData.find(
              (entry) =>
                entry.countryName === countryName && entry.month === month
            );

            if (!existingEntry) {
              existingEntry = {
                countryName,
                month,
                positive: 0,
                negative: 0,
                neutral: 0,
              };
              combinedData.push(existingEntry);
            }

            if (type === "Positive") {
              existingEntry.positive += numArticles;
            } else if (type === "Negative") {
              existingEntry.negative += numArticles;
            } else if (type === "Neutral") {
              existingEntry.neutral += numArticles;
            }
          });

          const part1Data = combinedData.map((data) => {
            const monthParts = data.month.split("-");
            const monthNumber = parseInt(monthParts[1], 10);

            return {
              countryName: data.countryName,
              month: monthNumber,
              positive: data.positive,
              negative: data.negative,
              neutral: data.neutral,
            };
          });

          function createTemplateObject(countryName, month) {
            return {
              countryName,
              month,
              positive: 0,
              negative: 0,
              neutral: 0,
            };
          }
          const countryDataMap = {};

          // Initialize the countryDataMap with the template objects
          part1Data.forEach((data) => {
            if (!countryDataMap[data.countryName]) {
              countryDataMap[data.countryName] = [];
            }
            countryDataMap[data.countryName][data.month - 1] = data;
          });

          const date = new Date();
          const currentMonths = date.getMonth(); 

          let months = [
            {name: "Jan", number: 1},
            {name: "Feb", number: 2},
            {name: "Mar", number: 3},
            {name: "Apr", number: 4},
            {name: "May", number: 5},
            {name: "Jun", number: 6},
            {name: "Jul", number: 7},
            {name: "Aug", number: 8},
            {name: "Sep", number: 9},
            {name: "Oct", number: 10},
            {name: "Nov", number: 11},
            {name: "Dec", number: 12}
          ];
        
          if(currentMonths >= 8){
            months = months.slice(currentMonths-8,currentMonths);
          }
          else{
            months = months.slice(currentMonths+4).concat(months.slice(0, currentMonths));
          }

          // Generate the complete set of data for each country
          const newData = [];
          for (const countryName in countryDataMap) {
            const countryData = countryDataMap[countryName];
            
            months.forEach((month) => {
              if (!countryData[month.number-1]) {
                // If the month data doesn't exist, create a template object
                const templateObject = createTemplateObject(countryName, month.number);
                newData.push(templateObject);
              } else {
                newData.push(countryData[month.number - 1]);
              }
            });
          }

          const transformedArray = newData.map((item) => {
            const { month, positive, negative, neutral } = item;
            const name = months.find((monthItem) => monthItem.number === month).name; // Adjusting for 0-based index

            return {
              name,
              pos: positive,
              neg: negative,
              neu: neutral,
              max: 0,
            };
          });

          const aggregatedData = {};

          transformedArray.forEach((data) => {
            const { name, pos, neg, neu, max } =
              data;
  
            if (!aggregatedData[name]) {
              aggregatedData[name] = {
                name,
                pos,
                neg,
                neu,
                max,
              };
            } else {
              aggregatedData[name].pos += pos;
              aggregatedData[name].neg += neg;
              aggregatedData[name].neu += neu;
              aggregatedData[name].max += max;
            }
          });
  
          // Convert the aggregatedData object back to an array of objects
          const resultArray = Object.values(aggregatedData);
          
          setDataForBar(resultArray);

          //-------------------POsitive chart data-----------------

          const transformedArrayPos = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months.find((monthItem) => monthItem.number === month).name; // Adjusting for 0-based index

            return {
              name,
              countryName: countryName,
              pos: positive,
              max: 0,
            };
          });

          const aggregatedDataPos = {};

          transformedArrayPos.forEach((data) => {
            const { name, countryName, pos, max } =
              data;
  
            if (!aggregatedDataPos[name]) {
                aggregatedDataPos[name] = {
                name,
                France: 0,
                Brazil: 0,
                Australia: 0,
                China: 0,
                USA: 0,
                Singapore: 0,
                Canada: 0,
                Japan: 0,
                Nigeria: 0,
                Pakistan: 0,
                Russia: 0,
                UAE: 0,
                UK: 0,
                max,
                };
                for (const country in aggregatedDataPos[name]) {
                if (country === countryName) {
                    aggregatedDataPos[name][country] = pos;
                }
                }
            } else {
                for (const country in aggregatedDataPos[name]) {
                if (country === countryName) {
                    aggregatedDataPos[name][country] = pos;
                }
                }
            }
          });
  
          // Convert the aggregatedData object back to an array of objects
          const resultArrayPos = Object.values(aggregatedDataPos);
          setDataForBarPos(resultArrayPos);


          //-------------------Neutral chart data-----------------

          const transformedArrayNeu = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months.find((monthItem) => monthItem.number === month).name; // Adjusting for 0-based index

            return {
              name,
              countryName: countryName,
              neu: neutral,
              max: 0,
            };
          });

          const aggregatedDataNeu = {};

          transformedArrayNeu.forEach((data) => {
            const { name, countryName, neu, max } =
              data;
  
            if (!aggregatedDataNeu[name]) {
                aggregatedDataNeu[name] = {
                name,
                France: 0,
                Brazil: 0,
                Australia: 0,
                China: 0,
                USA: 0,
                Singapore: 0,
                Canada: 0,
                Japan: 0,
                Nigeria: 0,
                Pakistan: 0,
                Russia: 0,
                UAE: 0,
                UK: 0,
                max,
                };
                for (const country in aggregatedDataNeu[name]) {
                if (country === countryName) {
                    aggregatedDataNeu[name][country] = neu;
                }
                }
            } else {
                for (const country in aggregatedDataNeu[name]) {
                if (country === countryName) {
                    aggregatedDataNeu[name][country] = neu;
                }
                }
            }
          });
  
          // Convert the aggregatedData object back to an array of objects
          const resultArrayNeu = Object.values(aggregatedDataNeu);
          setDataForBarNeu(resultArrayNeu);

          //-------------------Negative chart data-----------------

          const transformedArrayNeg = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months.find((monthItem) => monthItem.number === month).name; // Adjusting for 0-based index

            return {
              name,
              countryName: countryName,
              neg: negative,
              max: 0,
            };
          });

          const aggregatedDataNeg = {};

          transformedArrayNeg.forEach((data) => {
            const { name, countryName, neg, max } =
              data;
  
            if (!aggregatedDataNeg[name]) {
                aggregatedDataNeg[name] = {
                name,
                France: 0,
                Brazil: 0,
                Australia: 0,
                China: 0,
                USA: 0,
                Singapore: 0,
                Canada: 0,
                Japan: 0,
                Nigeria: 0,
                Pakistan: 0,
                Russia: 0,
                UAE: 0,
                UK: 0,
                max,
                };
                for (const country in aggregatedDataNeg[name]) {
                if (country === countryName) {
                    aggregatedDataNeg[name][country] = neg;
                }
                }
            } else {
                for (const country in aggregatedDataNeg[name]) {
                if (country === countryName) {
                    aggregatedDataNeg[name][country] = neg;
                }
                }
            }
          });
  
          // Convert the aggregatedData object back to an array of objects
          const resultArrayNeg = Object.values(aggregatedDataNeg);
          setDataForBarNeg(resultArrayNeg);

          //-------------------5EYES chart data-----------------

          const transformedArray5EYES = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months[Number(month) - 1]; // Adjusting for 0-based index

            if (countryName === "USA" || countryName === "UK" || countryName === "Canada" || countryName === "Australia")
            {
              return {
                name,
                countryName: countryName,
                pos: positive,
                neu: neutral,
                neg: negative,
                max: 0,
              };
            }
            return {
              name,
              countryName: countryName,
              pos: 0,
              neu: 0,
              neg: 0,
              max: 0,
            };
          });

          let aggregatedData5EYES;

          transformedArray5EYES.forEach((data) => {
            const { name, countryName, pos, neu, neg, max } =
              data;
  
            if (!aggregatedData5EYES) {
                aggregatedData5EYES = {
                allianceName: "Five Eyes [Without New Zealand]",
                logo: fiveEyesImg,
                pos,
                neu,
                neg,
                max,
                };
            } else {
              aggregatedData5EYES.pos += pos;
              aggregatedData5EYES.neu += neu;
              aggregatedData5EYES.neg += neg;
            }
          });

          //-------------------BRICS chart data-----------------

          const transformedArrayBRICS = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months[Number(month) - 1]; // Adjusting for 0-based index

            if (countryName === "Brazil" || countryName === "Russia" || countryName === "China")
            {
              return {
                name,
                countryName: countryName,
                pos: positive,
                neu: neutral,
                neg: negative,
                max: 0,
              };
            }
            return {
              name,
              countryName: countryName,
              pos: 0,
              neu: 0,
              neg: 0,
              max: 0,
            };
          });

          let aggregatedDataBRICS;

          transformedArrayBRICS.forEach((data) => {
            const { name, countryName, pos, neu, neg, max } =
              data;
  
            if (!aggregatedDataBRICS) {
                aggregatedDataBRICS = {
                allianceName: "BRICS [Without South Africa and India]",
                logo: bricsImg,
                pos,
                neu,
                neg,
                max,
                };
            } else {
              aggregatedDataBRICS.pos += pos;
              aggregatedDataBRICS.neu += neu;
              aggregatedDataBRICS.neg += neg;
            }
          });

          //-------------------QSD chart data-----------------

          const transformedArrayQSD = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months[Number(month) - 1]; // Adjusting for 0-based index

            if (countryName === "USA" || countryName === "Australia" || countryName === "Japan")
            {
              return {
                name,
                countryName: countryName,
                pos: positive,
                neu: neutral,
                neg: negative,
                max: 0,
              };
            }
            return {
              name,
              countryName: countryName,
              pos: 0,
              neu: 0,
              neg: 0,
              max: 0,
            };
          });

          let aggregatedDataQSD;

          transformedArrayQSD.forEach((data) => {
            const { name, countryName, pos, neu, neg, max } =
              data;
  
            if (!aggregatedDataQSD) {
                aggregatedDataQSD = {
                allianceName: "QSD [Without India]",
                logo: qsdImg,
                pos,
                neu,
                neg,
                max,
                };
            } else {
              aggregatedDataQSD.pos += pos;
              aggregatedDataQSD.neu += neu;
              aggregatedDataQSD.neg += neg;
            }
          });

          //-------------------UNSC chart data-----------------

          const transformedArrayUNSC = newData.map((item) => {
            const {countryName, month, positive, negative, neutral } = item;
            const name = months[Number(month) - 1]; // Adjusting for 0-based index

            if (countryName === "USA" || countryName === "UK" || countryName === "France" || countryName === "Russia" || countryName === "China")
            {
              return {
                name,
                countryName: countryName,
                pos: positive,
                neu: neutral,
                neg: negative,
                max: 0,
              };
            }
            return {
              name,
              countryName: countryName,
              pos: 0,
              neu: 0,
              neg: 0,
              max: 0,
            };
          });

          let aggregatedDataUNSC;

          transformedArrayUNSC.forEach((data) => {
            const { name, countryName, pos, neu, neg, max } =
              data;
  
            if (!aggregatedDataUNSC) {
                aggregatedDataUNSC = {
                allianceName: "UNSC",
                logo: unscImg,
                pos,
                neu,
                neg,
                max,
                };
            } else {
              aggregatedDataUNSC.pos += pos;
              aggregatedDataUNSC.neu += neu;
              aggregatedDataUNSC.neg += neg;
            }
          });

          let alliances = [aggregatedDataBRICS, aggregatedData5EYES, aggregatedDataQSD, aggregatedDataUNSC]
          setAllianceList(alliances);

        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllCountries();

  }, [tempName]);

  const allChart = () => {
    setIsPositive(false);
    setIsNeutral(false);
    setIsNegative(false);

  };

  const positiveChart = () => {
    setIsPositive(!isPositive);
    setIsNeutral(false);
    setIsNegative(false);
  };

  const neutralChart = () => {
    setIsPositive(false);
    setIsNeutral(!isNeutral);
    setIsNegative(false);
  };

  const negativeChart = () => {
    setIsPositive(false);
    setIsNeutral(false);
    setIsNegative(!isNegative);
  };

  let bg = backgroundImage;

  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    backgroundPosition: 'center',
    width: "100%",
    height: "100%",
    // Add other styles as needed
  };


  //////////////////////////Share handling/////////////////:
  let total = parseInt(countryData.positive)+parseInt(countryData.neutral)+parseInt(countryData.positive);
  let NegPercentage = parseInt((countryData.negative/total)*100)
  const websiteUrl = `https://kutniti.watch`
  const message = encodeURIComponent("Do you want to know how India is perceived in the world?\nGo check on:");

  function handleTweet() {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${websiteUrl}`;
    window.open(twitterUrl, "_blank");
  }

  function handleFb(){
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${websiteUrl}`;
    window.open(fbUrl, "_blank")
  }

  function handleLinkedIn()
  {
    const LinkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${websiteUrl}`;
    window.open(LinkedInUrl, "_blank")
  }

  function handleCopy(){
    const currentUrl = window.location.href;
            const tempTextarea = document.createElement("textarea");
            tempTextarea.value = currentUrl;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            document.execCommand("copy");
            document.body.removeChild(tempTextarea);

            const button = document.getElementById("CopyButton");
            const imgElement = button.querySelector('img'); // Find the img element within the button
            imgElement.src = check; 

            // You may want to reset the button text after a certain time (e.g., 2 seconds)
            setTimeout(() => {
              imgElement.src = copy;
            }, 3000);
  }

  function toggleShareOptions() {
    var shareOptions = document.getElementById("shareOptions");
    if (shareOptions.style.display === "flex") {
        shareOptions.style.display = "none";
    } else {
        shareOptions.style.display = "flex";
    }
}



  //----------------------------sort Alphabetically Country--------------------
  const sortAlphabeticallyCountry = () => {
    setClickCountCountry(!clickCountCountry);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountCountry){
       sortedData = [...allianceList].sort((a, b) => {
        return a.allianceName.localeCompare(b.allianceName);
      });
    }else{
       sortedData = [...allianceList].sort((a, b) => {
        return b.allianceName.localeCompare(a.allianceName);
      });
    }
    

    setAllianceList(sortedData);
  };
  
  //----------------------------sort Total--------------------
  const sortDataByTotal = () => {
    setClickCountTotal(!clickCountTotal);
    setClickCountCountry(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountTotal){
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = a.pos + a.neg + a.neu;
        const totalB = b.pos + b.neg + b.neu;
        return totalB - totalA; // Sort in descending order
      });
    }else{
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = a.pos + a.neg + a.neu;
        const totalB = b.pos + b.neg + b.neu;
        return totalA - totalB; // Sort in descending order
      });
    }

    setAllianceList(sortedData);
  };

  //----------------------------sort Positive--------------------
  const sortDataByPositive = () => {
    setClickCountPosite(!clickCountPositive);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountPositive){
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.pos/(a.pos + a.neu + a.neg))*100;
        const totalB = (b.pos/(b.pos + b.neu + b.neg))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.pos/(a.pos + a.neu + a.neg))*100;
        const totalB = (b.pos/(b.pos + b.neu + b.neg))*100;
        return totalB - totalA; // Sort in dscending order
      });
    }
    setAllianceList(sortedData);
  };

  //----------------------------sort Negative--------------------
  const sortDataByNegative = () => {
    setClickCountNegative(!clickCountNegative);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);

    let sortedData;
    if(clickCountNegative){
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.neg/(a.pos + a.neu+ a.neg))*100;
        const totalB = (b.neg/(b.pos + b.neu + b.neg))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.neg/(a.pos + a.neu+ a.neg))*100;
        const totalB = (b.neg/(b.pos + b.neu + b.neg))*100;
        return totalB - totalA; // Sort in descending order
      });
    }
    setAllianceList(sortedData);
  };
  
  //----------------------------sort Neutral--------------------
  const sortDataByNeutral = () => {
    setClickCountNeutral(!clickCountNeutral);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountNeutral){
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.neu/(a.pos + a.neu + a.neg))*100;
        const totalB = (b.neu/(b.pos + b.neu + b.neg))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allianceList].sort((a, b) => {
        const totalA = (a.neu/(a.pos + a.neu + a.neg))*100;
        const totalB = (b.neu/(b.pos + b.neu + b.neg))*100;
        return totalB - totalA; // Sort in desscending order
      });
    }
    setAllianceList(sortedData);
  };

  return (
    <div style={containerStyle} className="w-full font-custom">
      <Navbar />
      {isLaptop && (
        <div id="capture" className="flex min-h-screen">
          <div className="w-full py-4">

            <h1 className="font-bold text-3xl p-5 invisible">
              Providing Free spacing
            </h1>

            <div className=" lg:mx-2 lg:p-5 mx-0 p-2 rounded-2xl border border-gray-600">
              <div className="">
                <div className="lg:w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 flex justify-between items-center rounded-xl shadow-2xl p-1">
                  <div className="flex m-1">
                    <div className="rounded-lg overflow-hidden ">
                      <img
                        src="https://img.freepik.com/free-vector/illustration-global-icon_53876-9267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702252800&semt=ais"
                        alt="Country Flag"
                        className=" h-10 rounded-lg"
                      />
                    </div>

                    <div className="text-xl ml-2 self-center">
                      <div className="text-2xl font-bold">The {countryData.name}</div>
                    </div>
                  </div>

                  <div className="flex">
                    <div id="shareOptions" class="hidden flex">
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleFb}>
                        <img
                          src={facebook}
                          alt="Facebook Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleTweet}>
                        <img
                          src={twitter}
                          alt="Twitter Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleLinkedIn}>
                        <img
                          src={linkedin}
                          alt="LinkedIn Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div id="CopyButton" className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleCopy}>
                        <img
                            src={copy}
                            alt="Copy Button"
                            className="w-18 h-10 rounded-lg"
                          />
                      </div>
                    </div>
                    <button
                      className="bg-white cursor-pointer text-white font-bold m-auto p-auto rounded-lg shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 border"
                      onClick={toggleShareOptions}
                    >
                      <img
                        src={share}
                        alt="Share Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`flex w-full  space-x-5 overflow-x-auto`}
              >
                <div className={`w-1/3 mt-2 mb-2 space-y-2`}>
                  <div className="bg-opacity-50 bg-white border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl w-full h-[445px] flex r-0">
                    <div className="pb-2 mx-auto">
                      <p className="flex justify-center font-bold text-xl mt-5">
                        Sentiment of the {countryData.name} towards India
                      </p>
                      <div className="">
                        <div className="flex justify-center">
                          <PieChartComponent
                              hoveredPositive={countryData.positive}
                              hoveredNegative={countryData.negative}
                              hoveredNeutral={countryData.neutral}
                          />
                        </div>
                        <div className="flex justify-between">
                          <p className="text-red-500">
                            Negative: {countryData.negative}
                          </p>
                          <p className="text-yellow-300">
                            Neutral: {countryData.neutral}
                          </p>
                          <p className="text-green-500">
                            Positive: {countryData.positive}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="border border-gray-300 bg-white bg-opacity-50 backdrop-blur-[3px] p-4 flex justify-center space-x-4  shadow-md rounded-xl w-full">
                      <p className="text-gray-700 ">
                        Articles Published by the {countryData.name}
                      </p>

                      <div className="w-px h-6 bg-gray-800"></div>
                      <p className="text-gray-700 text-base">
                        {parseInt(countryData.positive) +
                          parseInt(countryData.negative) +
                          parseInt(countryData.neutral)}
                      </p>
                    </div>
                  </div>

                </div>


                <div className={`w-2/3  mt-2 mb-2 space-y-2`}>
                  <div className=" bg-opacity-50 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-md rounded-xl w-full h-1000 overflow-x-auto">
                    <p className="flex justify-center text-xl font-bold mt-5">
                    Evolution over the months
                    </p>
                    {isPositive ? 
                    (<AreaChartComponentPos chartData={dataForBarPos} />)
                    : ( isNeutral ? 
                    (<AreaChartComponentNeu chartData={dataForBarNeu} />)
                    : ( isNegative ? 
                    (<AreaChartComponentNeg chartData={dataForBarNeg} />)
                    :(
                    <AreaChartComponent chartData={dataForBar} />
                    )
                    ))}
                  </div>
                  <div className="border border-gray-300 bg-white bg-opacity-50 backdrop-blur-[3px] p-2 flex justify-center space-x-4  shadow-md rounded-xl w-full">
                    <div className="flex w-1/2 justify-center space-x-5">
                        <div className="text-gray-700 self-center text-xl w-1/5">
                            Filter:
                        </div>
                        <div className="flex w-1/5">
                            <div
                                onClick={negativeChart}
                                className={`w-full text-center cursor-pointer p-2 py-1 font-semibold rounded-lg border-2 border-custom-red bg-custom-red hover:bg-opacity-30 ${isNegative ? "bg-opacity-30" : "bg-opacity-0"}`}
                            >
                                Negative
                            </div>
                        </div>
                        <div className="flex w-1/5">
                            <div
                                onClick={neutralChart}
                                className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-yellow2 bg-custom-yellow2 hover:bg-opacity-30 ${isNeutral ? "bg-opacity-30" : "bg-opacity-0"}`}
                            >
                                Neutral
                            </div>
                        </div>
                        <div className="flex w-1/5">
                            <div
                                onClick={positiveChart}
                                className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-green bg-custom-green hover:bg-opacity-30 ${isPositive ? "bg-opacity-30" : "bg-opacity-0"}`}
                            >
                                Positive
                            </div>
                        </div>
                        <div className="flex w-1/5">
                            <div
                                onClick={allChart}
                                className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-gray-400 bg-gray-400 hover:bg-opacity-30 ${!isPositive && !isNeutral && !isNegative  ? "bg-opacity-30" : "bg-opacity-0"}`}
                            >
                                All
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-md rounded-xl p-4">
                  <div className=" my-1">
                    <h2 className="text-2xl font-bold mb-5  ">
                      Biggest country alliance
                    </h2>
                  </div>

                  <div className="flex justify-between mb-4">

                    <div className="w-1/4">
                      <button className="flex w-full px-5 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortAlphabeticallyCountry}>
                        <div className="font-semibold ">
                          Alliance
                        </div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6687/6687601.png" alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="">
                      <button className="flex w-full px-2 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByTotal}>
                        <div className="font-semibold ">
                          Articles Published
                        </div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6687/6687601.png" alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="flex justify-between w-1/2 px-2">

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-red bg-custom-red bg-opacity-0 hover:bg-opacity-30"  onClick={sortDataByNegative}>
                        <div className="cursor-pointer font-semibold">
                          Negative
                        </div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6687/6687601.png" alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                      
                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-yellow2 bg-custom-yellow2 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByNeutral}>
                        <div className="cursor-pointer font-semibold">
                          Neutral
                        </div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6687/6687601.png" alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                      
                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-green bg-custom-green bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByPositive}>
                        <div className="cursor-pointer ml-3 font-semibold">
                          Positive
                        </div>
                        <img src="https://cdn-icons-png.flaticon.com/512/6687/6687601.png" alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                    </div>
                  </div>

                  <hr className="border-t-1 border-black w-full" />
                  {allianceList && (
                    <div className=" items-center">
                      {allianceList.map((alliance, index) => (
                        <div className="mt-4 mb-4">
                          <div key={index} className="flex justify-between transform hover:scale-105 m-2 p-1 hover:cursor-pointer hover:shadow-2xl  hover:rounded-lg  hover:transition-transform transition hover:duration-500"
                          >

                            <div className="flex w-1/4">
                              <div className="self-center mr-2 w-full space-x-2 flex overflow-hidden">
                                {alliance.logo && (
                                  <img
                                    src={alliance.logo}
                                    alt={"Logo: "+alliance.allianceName}
                                    className="h-10 w-1/3 self-center"
                                  />
                                )}
                                <h2 className="text-sm w-2/3 self-center font-semibold">
                                {alliance.allianceName}
                                </h2>
                              </div>
                            </div>

                            <div className="self-center">
                              <img
                                src={articleIcon}
                                alt="Article Icon"
                                className="w-[25px] h-[25px] self-center"
                              />
                              <p className="text-sm text-center mt-px self-center">{alliance.pos+alliance.neg+alliance.neu}</p>
                            </div>

                            <div className="w-1/2">
                              <BigSingleHorizontalBar
                                positiveValue={alliance.pos}
                                negativeValue={alliance.neg}
                                neutralValue={alliance.neu}
                              />
                            </div>
                          </div>
                          <hr className="border-t-2 border-black w-full" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div id="capture" className="flex h-screen">
          <div className="w-full py-4">
            <h1 className="font-bold text-xl p-4 invisible">
              Providing Free spacing
            </h1>

            <div className=" lg:mx-2 lg:p-5 mx-0 p-2">
              <div className="">
                <div className="lg:w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 flex justify-between items-center rounded-xl shadow-2xl p-1">
                  <div className="flex m-1">
                    <div className="rounded-lg overflow-hidden ">
                        <img
                          src="https://img.freepik.com/free-vector/illustration-global-icon_53876-9267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702252800&semt=ais"
                          alt="Country Flag"
                          className=" h-10 rounded-lg"
                        />
                    </div>

                    <div className="text-xl ml-2 self-center">
                      <div className="text-2xl font-bold">The {countryData.name}</div>
                    </div>
                  </div>

                  <div className="flex">
                    <div id="shareOptions" class="hidden flex">
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleFb}>
                        <img
                          src={facebook}
                          alt="Facebook Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleTweet}>
                        <img
                          src={twitter}
                          alt="Twitter Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleLinkedIn}>
                        <img
                          src={linkedin}
                          alt="LinkedIn Button"
                          className="w-18 h-10 rounded-lg"
                        />
                      </div>
                      <div id="CopyButton" className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleCopy}>
                        <img
                            src={copy}
                            alt="Copy Button"
                            className="w-18 h-10 rounded-lg"
                          />
                      </div>
                    </div>
                    <button
                      className="bg-white cursor-pointer text-white font-bold m-auto p-auto rounded-lg shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 border"
                      onClick={toggleShareOptions}
                    >
                      <img
                        src={share}
                        alt="Share Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`grid grid-cols-1 lg:grid-cols-2 lg-8 overflow-x-auto`}
              >
                <div className={`max-w-[520px] mt-2 mb-2 space-y-2`}>
                  <div className="bg-opacity-50 bg-white border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl mt-5 mb-2 ">
                      <div className="m-2">
                        <div className="flex text-center font-bold  w-full text-xl ">
                          Sentiment of the {countryData.name} towards India
                        </div>
                        <div className="flex">
                          <div className="w-1/2 flex justify-end">
                            <SmallPieChart
                              hoveredPositive={countryData.positive}
                              hoveredNegative={countryData.negative}
                              hoveredNeutral={countryData.neutral}
                            />
                          </div>

                          <div className="w-1/2 self-center">
                            <p className="text-custom-red m-1">
                              Negative: {countryData.negative}
                            </p>
                            <p className="text-custom-yellow2 m-1">
                              Neutral: {countryData.neutral}
                            </p>
                            <p className="text-custom-green m-1">
                              Positive: {countryData.positive}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  

                  <div className="">
                      <div className="p-2 flex bg-opacity-50 bg-white border backdrop-blur-[3px] border-gray-300 shadow-md rounded-xl max-w-[500px]">
                        <p className="text-gray-700 mx-4 ">
                          Articles Published by the {countryData.name}
                        </p>

                        <div className="w-px h-6 bg-gray-800  self-center"></div>
                        <p className="text-gray-700 text-base mx-4 self-center">
                          {parseInt(countryData.positive) +
                            parseInt(countryData.negative) +
                            parseInt(countryData.neutral)}
                        </p>
                      </div>
                  </div>
                </div>


                <div className={`w-full h-1/3  mt-2 mb-2 space-y-2`}>
                  <div className=" bg-opacity-50 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-md rounded-xl w-full h-1000 overflow-x-auto">
                      <p className="flex justify-center text-xl font-bold mt-5">
                      Evolution over the months
                      </p>
                      {isPositive ? 
                      (<AreaChartComponentPos chartData={dataForBarPos} />)
                      : ( isNeutral ? 
                      (<AreaChartComponentNeu chartData={dataForBarNeu} />)
                      : ( isNegative ? 
                      (<AreaChartComponentNeg chartData={dataForBarNeg} />)
                      :(
                      <AreaChartComponent chartData={dataForBar} />
                      )
                      ))}
                  </div>
                  <div className="border border-gray-300 bg-white bg-opacity-50 backdrop-blur-[3px] p-2 flex justify-center space-x-4  shadow-md rounded-xl w-full">
                    <div className="flex w-full justify-center space-x-3">
                      <div className="flex w-1/4 text-sm">
                        <div
                            onClick={negativeChart}
                            className={`w-full text-center cursor-pointer p-2 py-1 font-semibold rounded-lg border-2 border-custom-red bg-custom-red hover:bg-opacity-30 ${isNegative ? "bg-opacity-30" : "bg-opacity-0"}`}
                        >
                            Negative
                        </div>
                      </div>
                      <div className="flex w-1/4 text-sm">
                        <div
                            onClick={neutralChart}
                            className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-yellow2 bg-custom-yellow2 hover:bg-opacity-30 ${isNeutral ? "bg-opacity-30" : "bg-opacity-0"}`}
                        >
                            Neutral
                        </div>
                      </div>
                      <div className="flex w-1/4 text-sm">
                        <div
                            onClick={positiveChart}
                            className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-green bg-custom-green hover:bg-opacity-30 ${isPositive ? "bg-opacity-30" : "bg-opacity-0"}`}
                        >
                            Positive
                        </div>
                      </div>
                      <div className="flex w-1/4 text-sm">
                        <div
                            onClick={allChart}
                            className={`w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-gray-400 bg-gray-400 hover:bg-opacity-30 ${!isPositive && !isNeutral && !isNegative  ? "bg-opacity-30" : "bg-opacity-0"}`}
                        >
                            All
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-5 w-full">
                <div className=" my-1">
                  <h2 className="text-2xl font-bold mb-5 text-center">
                    Most Read Newspapers of {countryData.name}
                  </h2>
                </div>
                {allianceList && (
                  <div className=" items-center">
                    {allianceList.map((alliance, index) => (
                      <div className=" ">
                        <div key={index} className="flex space-x-1 justify-between bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 shadow-md rounded-lg my-2 p-2">
                          <div className="h-full w-[40%] mr-2 overflow-hidden flex">
                            <div className="w-full flex space-x-1">
                              {alliance.logo && (
                                <img
                                  src={alliance.logo}
                                  alt="Logo"
                                  className="h-7 w-1/3 self-center"
                                />
                              )}
                              <h2 className="text-xs w-2/3 self-center font-semibold">
                                {alliance.allianceName}
                              </h2>
                            </div>
                          </div>

                          <div className="flex justify-center w-[20%]">
                            <p className="self-center text-lg mr-1">
                              {Math.floor((alliance.neg/(alliance.pos+alliance.neu+alliance.neg))*100)+"%"}
                            </p>
                            <div className="self-center">
                            <MicroPieChart
                              hoveredPositive={Math.floor((alliance.neg/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              hoveredNegative={100 - Math.floor((alliance.neg/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              fillType="negative"
                            />
                            </div>
                          </div>

                          <div className="flex  justify-center w-[20%]">
                            <p className="self-center text-lg mr-1">
                              {Math.floor((alliance.neu/(alliance.pos+alliance.neu+alliance.neg))*100)+"%"}
                            </p>
                            <div className="self-center">
                            <MicroPieChart
                              hoveredPositive={Math.floor((alliance.neu/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              hoveredNegative={100 - Math.floor((alliance.neu/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              fillType="neutral"
                            />
                            </div>
                          </div>

                          <div className="flex justify-center w-[20%]">
                            <div className="self-center">
                              <p className=" text-lg mr-1">
                                {Math.floor((alliance.pos/(alliance.pos+alliance.neu+alliance.neg))*100)+"%"}
                              </p>
                            </div>
                            <div className="self-center">
                            <MicroPieChart
                              hoveredPositive={Math.floor((alliance.pos/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              hoveredNegative={100 - Math.floor((alliance.pos/(alliance.pos+alliance.neu+alliance.neg))*100)}
                              fillType="positive"
                            />
                            </div>
                          </div>
                        </div>
                        <hr className="border-t-2 border-black w-full" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CountryDetails;
