import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer} from 'recharts';




export default class CustomBarChart extends PureComponent {
  render() {
    let { chartData } = this.props;


    let maxPossibleSum = 0;
chartData.forEach(entry => {
  const sum = entry.pos + entry.neg + entry.neu;
  if (sum > maxPossibleSum) {
    maxPossibleSum = sum;
  }
});

// Update max values for entries with all 0 pos, neg, neu values
chartData.forEach(entry => {
  if (entry.neg === 0 && entry.pos === 0 && entry.neu === 0) {
    entry.max = maxPossibleSum;
  }
});
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{
            top: 50,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar dataKey="neg" stackId="a" fill="#ee334d" barSize={100}/>
          <Bar dataKey="pos" stackId="a" fill="#74bf50" barSize={100}/>
          <Bar dataKey="neu" stackId="a" fill="#fac80c" barSize={100}/>
          <Bar dataKey="max" stackId="a" fill="#d6cec3" barSize={100}/>
          {/* <text x="50%" y={30} textAnchor="middle" fontSize={18}>
            Evolution over the months
          </text> */}
        </BarChart>
        </ResponsiveContainer>
    );
  }
}

