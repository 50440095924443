import React from "react";

const NewspaperPoliticalCard = ({ firstValue }) => {
  return (
    <div className="bg-opacity-0 backdrop-blur-[2px] w-full h-full rounded-lg shadow-2xl border p-2 pt-1 flex">
        <div className="self-center w-full">
          <div className="flex text-center justify-center mb-2 text-sm">Political Inclination</div>
          <div className=" text-[100%] text-center font-bold mb-2 mt-3">{firstValue}</div>
        </div>
    </div>
  );
};

export default NewspaperPoliticalCard;