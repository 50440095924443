import React, { PureComponent, useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#74bf50', '#ee334d', '#fac80c', '#FF8042'];

const CustomPieChart = ({ hoveredPositive, hoveredNegative, hoveredNeutral}) => {
  const [data, setData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [valueShowed, setValueShowed] = useState("");
  const [textColor, setTextColor] = useState("text-white")

  // const handleLocalStorageChange = () => {
  //   // const hoveredPositive = parseFloat(localStorage.getItem('hoveredPositive')) || 0;
  //   // const hoveredNegative = parseFloat(localStorage.getItem('hoveredNegative')) || 0;
  //   // const hoveredNeutral = parseFloat(localStorage.getItem('hoveredNeutral')) || 0;

  //   if (hoveredPositive !== 0 || hoveredNegative !== 0 || hoveredNeutral !== 0) {
  //     setShowChart(true);
  //     setData([
  //       { name: 'Positive', value: hoveredPositive },
  //       { name: 'Negative', value: hoveredNegative },
  //       { name: 'Neutral', value: hoveredNeutral },
  //       { name: 'Group D', value: 0 }, // You can set this to a default value
  //     ]);
  //   } else {
  //     setShowChart(false);
  //   }
  // };

  useEffect(() => {

    if (hoveredPositive !== 0 || hoveredNegative !== 0 || hoveredNeutral !== 0) {
      setShowChart(true);
      setData([
        { name: 'Positive', value: hoveredPositive },
        { name: 'Negative', value: hoveredNegative },
        { name: 'Neutral', value: hoveredNeutral },
        { name: 'Group D', value: 0 }, // You can set this to a default value
      ]);
      setValueShowed(Math.floor((hoveredNegative/(hoveredPositive + hoveredNegative + hoveredNeutral))*100)+"%");
      setTextColor("text-red-500");
    } else {
      setShowChart(false);
    }

    // handleLocalStorageChange();

    // // Attach local storage event listeners
    // window.addEventListener('storage', handleLocalStorageChange);

    // return () => {
    //   // Detach event listeners on component unmount
    //   window.removeEventListener('storage', handleLocalStorageChange);
    // };
  }, [hoveredNegative]);

  const renderCustomizedLabel = (props) => {
    const {fill, value} = props;
    setValueShowed(`${Math.floor((value/(hoveredPositive + hoveredNegative + hoveredNeutral))*100)}%`);
    if (fill === '#74bf50')
    {
      setTextColor("text-custom-green");
    }
    else if (fill === '#fac80c')
    {
      setTextColor("text-custom-yellow2");
    }
    else if (fill === '#ee334d')
    {
      setTextColor("text-custom-red");
    }
    else
    {
      setTextColor("text-white");
    }
    return null
  };

  return (
    <div className="relative flex">
      {showChart && (
        <PieChart width={150} height={150}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            //labelLine={false}
            onClick={renderCustomizedLabel}
            innerRadius={25}
            outerRadius={60}
            paddingAngle={0}
            cornerRadius={0}
            
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      )}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-opacity-70 bg-white backdrop-blur-[3px] w-[50%] h-[50%] p-2 rounded-full shadow-lg"></div>
      <div className={`absolute top-1/2 left-1/2 transition ease-in-out delay-50 transform -translate-x-1/2 -translate-y-1/2 ${textColor} text-xl font-bold`}>{valueShowed}</div>
    </div>
  );
};

export default CustomPieChart;
